.header_dark {
  position: relative;
  display: flex;
  padding: 0 2.85vw 0 2.85vw;
  justify-content: space-between;
  align-items: center;
  border-top: 4px solid #d62344;
  background: #1e0d12;
  z-index: 1000;
}

.header_nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.85vw;
}
.header_nav_a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #fafafa;
  border-bottom: 4px solid transparent;
}
.header_nav_a a,
.header_nav_a a:visited,
.header_nav_a a:active {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fafafa;
}
.header_nav_p {
  font-family: "Inter", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fafafa;
}

.header_button {
  position: relative;
  width: 11.36vw;
  border: 2px solid #fff;
  background-color: transparent;
  cursor: pointer;
}
.header_button:hover {
  background: linear-gradient(96deg, #cc1d4a 0%, #df352b 100%);
}
.header_button:hover .header_button_p {
  color: #fff;
}
.header_button a,
.header_button a:visited,
.header_nheader_buttonav_a a:active {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
}
.header_button_p {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.header_nav_a:hover {
  border-bottom: 4px solid #cc1d4a;
  height: 80px;
  cursor: pointer;
}
/* @keyframes slideToCenter {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
} */
/* .header_button {
  position: relative;
  width: 160px;
  border-left: 2px solid #9e0029;
  background: linear-gradient(to right, #cc1d4a, #9e002900),
    radial-gradient(circle at 100% 50%, #f89a5b, #cc1d4a 1000%);
  cursor: pointer;
  transition: background-size 0.5s, background-position 0.5s;
}
.header_button::before,
.header_button::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(to right, #9e0029, transparent 100%);
  pointer-events: none;
}

.header_button::before {
  top: 0;
}

.header_button::after {
  bottom: 0;
}

.header_button:hover {
  background: linear-gradient(
      90deg,
      #cc1d4a,
      #cc1d4a 50%,
      #f89a5b 75%,
      #cc1d4a 100%
    ),
    linear-gradient(to bottom right, #f89a5b 0%, #cc1d4a 50%) bottom right / 50%
      50% no-repeat,
    linear-gradient(to bottom left, #f89a5b 0%, #cc1d4a 50%) bottom left / 50%
      50% no-repeat,
    linear-gradient(to top left, #f89a5b 0%, #cc1d4a 50%) top left / 50% 50%
      no-repeat,
    linear-gradient(to top right, #f89a5b 0%, #cc1d4a 50%) top right / 50% 50%
      no-repeat;
  background-size: 200% 100%, 50% 50%, 50% 50%, 50% 50%, 50% 50%; 
  animation: slideToCenter 2s forwards;
} */
