/* Main container using flex to position buttons and content */
.AzureInfoContainer {
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
}

/* Buttons container */
.Azure_Info_containerFirst {
  display: flex;
  gap: 40px;
  border-bottom: 3px solid #d8d8d8;
}

/* Button styles */
.Azure_Info_button {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  cursor: pointer;
  color: #1e0d12;
  position: relative;
}

/* Active button style */
.Azure_Info_active {
  border-bottom: 3px solid #d41e4d;
}

/* Specific style for individual buttons */
.Azure_Info_individual {
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%;
}

.content_MoreInfo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 950px;
}

.Azure_Info_container P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
