.accordionTender {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.accordionTender-item {
  background-color: #fff;
  width: 1000px;
}

.accordionTender-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background: transparent;
}
.accordionTender-title {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}

.accordionTender-content {
  /* width: 80%; */
  padding: 15px 25px;
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  border-top: none;
  background-color: #fff;
}

.accordionTender_second {
}

/* For small devices (mobile phones)  Slide in Device  */
@media (max-width: 475px) {
  .accordionTender {
    background-color: #f5f5f5;
  }

  .accordionTender-item {
    background-color: #fff;
    width: 350px;
  }

  .accordionTender-title {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.023%; /* 30.005px */
    padding-top: 5px;
  }

  .accordionTender-header {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    background: transparent;

    /*border-bottom: 2px solid #d21d4c;*/
  }
  .Plus {
    width: 25px;
  }

  .accordionTender-content {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 24.004px */
    border-top: none;
    background-color: #fff;
  }

  .accordionTender li {
    padding: 10px 5px;
    font-size: 15px;
    font-family: "Gotham", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 24.004px */
  }
}
