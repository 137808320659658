.Azure_P6_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 20px 20px;
  justify-content: flex-start;
  border-radius: 7px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(192, 192, 192, 0.25);
  width: 250px;
  height: 250px;
  gap: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Azure_P6_S2_Box_H3 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
  width: 250px;
}
.Azure_P6_S2_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
  width: 200px;
}

.Azure_P6_S2_Box:hover {
  background: #d21d4c;
}

.Azure_P6_S2_Box:hover .Azure_P6_S2_Box_H3,
.Azure_P6_S2_Box:hover .Azure_P6_S2_Box_P {
  color: #fff;
}
@media (max-width: 475px) {
  .Azure_P6_S2_Box {
    align-items: center;
    gap: 30px;
    height: auto;
    width: auto;
  }
}
