/* Main container using flex to position buttons and content */
.HomePartFiveInfoContainer {
  display: flex;
  gap: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1000px;
}

/* Buttons container */
.HomePartFiveInfo_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 48px;
  flex-wrap: wrap;
}

/* Button styles */
.HomePartFiveInfo_button {
  display: flex;
  align-self: stretch;
  cursor: pointer;
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}
/* Active button style */
.HomePartFiveInfo_active {
  /* border-bottom: 3px solid #d41e4d; */
  font-weight: 700;
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}

/* Content container with padding and flex positioning */
.HomePartFiveInfo_content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  margin-bottom: 50px;
}

/* Container for the content including h3 and paragraphs */
.HomePartFiveInfo_MoreInfo_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.HomeP5_Img_MoreInfo {
  margin-top: 30px;
  width: 350px;
}

/* Style for the h3 tag */
.Home_P5_S1_H3 {
  font-family: "Gotham", sans-serif;
  line-height: 150.023%;
  margin-bottom: 20px;
  text-align: left;
  text-transform: none;
  color: var(--Red, #cc1d4a);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

/* Style for paragraphs */
.HomePartFiveInfo_MoreInfo_container_P {
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 150.023%;
  margin: 0;
  margin-top: 10px;
  text-align: left;
  text-transform: none;
  color: #1e0d12;
  font-style: normal;
  width: 505.984px;
}

.Home_P5_Info_S1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  width: 400px;
}
.Home_P5_Info_S1_H3 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 48.007px */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home_P5_S1_Img_Rotate {
  width: 35px;
  animation: rotate 5s linear infinite;
}
@media (max-width: 475px) {
  .HomePartFiveInfoContainer {
    gap: 20px;
    width: 100%;
  }
  .HomePartFiveInfo_container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px 20px;
    flex-wrap: wrap;
  }
  .HomePartFiveInfo_button {
    font-size: 16px;
  }
  .HomePartFiveInfo_active {
    font-size: 16px;
  }
  .HomePartFiveInfo_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 0;
  }
  .HomePartFiveInfo_MoreInfo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Home_P5_Info_S1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 300px;
    border-bottom: 2px solid #d8d8d8;
  }
  .Home_P5_Info_S1_H3 {
    font-size: 20px;
  }
  .HomeP5_Img_MoreInfo {
    margin-top: 30px;
    width: 300px;
  }
  .HomePartFiveInfo_MoreInfo_container_P {
    width: 324px;
    font-size: 16px;
    align-items: center;
    text-align: center;
    margin-top: 24px;
  }
  .HomePartFiveInfo_content {
    flex-direction: column-reverse;
  }
}
