.AwsPartner_container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.AwsPartner_container > * {
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
}
@media (max-width: 475px) {
  .body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .AwsPartner_container > * {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
}
.Aws_FirstPart {
  /* background-image: url(../../assets/svg/sap_banner.svg); */
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.Aws_P1_P1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: -5vh;
}
.Partners_Aws_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  min-width: 100%;
  /* min-height: 100%; */
  width: 100%;
  height: 80vh;
  z-index: -1;
}
.Aws_P1_P1_H1 {
  color: #fff;
  font-family: "Galifex", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150.023%;
  margin: 0;
}
.Aws_FirstPart_H1 {
  color: #fff;
  font-family: "Galifex", Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-top: 0;
  margin: 0;
}
.Aws_FirstPart_P {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 930px;
}
.AwsStarMobile {
  display: none;
}

/*start responsive first part*/
@media (max-width: 475px) {
  .Aws_FirstPart {
    background-image: url(../../assets/images/sap_bannerMobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }

  .Aws_P1_P1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 5vh;
  }
  .Aws_P1_P1_H1 {
    text-align: center;
    color: #fff;
    font-family: "Galifex", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 150.023%;
    padding-top: 15px;
  }
  .Aws_FirstPart_H1 {
    text-align: center;
    padding-top: 5px;
    color: #fff;
    font-family: "Galifex", Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%;
    margin-top: 0;
  }
  .Aws_FirstPart_P {
    display: none;
  }
  .Partners_Aws_VideoBackground {
    display: none;
  }
}

/* style second part */
.Aws_SecondPart_container {
  background: #fbfbfb;
  margin-top: -56px;
}
.Aws_SecondPart {
  height: auto;
  background: #f9f8f8;
  flex-shrink: 0;
  margin-top: 30px;
}
.Aws_P2_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}
/* style Third Part */
.Aws_ThirdPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}
.Aws_P3_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Aws_P3_S2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.Aws_P3_S2_Element {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 30px;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  background: #eeeeee86;
}
.Aws_P3_S2_Element_Svg {
  width: 120px;
  margin-top: -85px;
}
.Aws_P3_S2_Element_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  text-align: center;

  line-height: 150.023%; /* 33.005px */
  margin: 0;
}
.Aws_P3_S2_Element_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 30.005px */
}
.Aws_P3_S3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 920px;
}

.Aws_P3_S3_P {
  color: #696969;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%; /* 36.005px */
}
.Aws_P3_S4_button {
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  border: none;
  /* for button text */
  color: #fff;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.Aws_ColorSpace {
  background: #fbfbfb;
  height: 56px;
}
/* start part-3 responsive */
@media (max-width: 475px) {
  .Aws_ThirdPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 70px;
  }
  .Aws_P3_S2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
  .Aws_P3_S3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
  }

  .Aws_P3_S3_P {
    color: #696969;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 36.005px */
  }
}
/* style Fourth Part */
.Aws_FourthPartContainer {
  display: flex;
  flex-direction: row;
  gap: 100px;
  align-items: center;
  justify-content: center;
}
.Aws_P4_S1 {
  margin-top: 50px;
  /* padding-left: 50px; */
}
.Aws_P4_S2_E1 h2 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 46px; /* 191.667% */
  text-transform: uppercase;
  width: 770px;
}
.Aws_P4_S2_E2_Container {
  display: flex;
  gap: 120px;
}
.Aws_P4_S2_E2_List {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Aws_P4_S2_E2_List li {
  color: #474747;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
.Aws_ColorSpaceEnd {
  background: #fbfbfb;
  margin-bottom: -56px;
  height: 112px;
}
.AwsLogo {
  width: 100px;
}
/* Start part-4 responsive */
@media (max-width: 475px) {
  .Aws_FourthPartContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .Aws_P4_S1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding-left: 0px;
  }
  .Aws_P4_S2_E2_Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 350px;
  }
  .Aws_P4_S2_E1 h2 {
    color: var(--Red, #cc1d4a);
    font-family: "Gotham" sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 46px; /* 191.667% */
    text-transform: uppercase;
    width: 380px;
    text-align: center;
  }
  .Aws_P4_S2_E2_List {
    align-items: flex-start;
  }
  .Aws_P4_S2_E2_List li {
    color: #474747;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    /* text-align: center; */
  }
  .Aws_P4_S2_E2_Container {
    padding: 0 20px;
    gap: 0;
    width: auto;
  }
}
