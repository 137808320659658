.S4hanaContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;
  overflow: hidden;
}

/* style First Part */
.S4hanaFirstPart {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 300px;
  padding: 56px 80px 32px 80px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex-shrink: 0;
  position: relative;
}
.Sap_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.S4hana_P1_S1 {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: -30px;
}
.S4hana_P1_S1 a,
.S4hana_P1_S1 a:visited,
.S4hana_P1_S1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.S4hana_P1_S1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
}
.KeyboardArrowLeftIcon {
  color: #fff;
}
.S4hana_P1_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.S4hana_P1_H1 {
  color: var(--white, #fff);
  font-family: "Galifex";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
}
.S4hana_P1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

/* end */
/* style Second Part */
.S4hanaSecondPart {
  display: flex;
  align-items: center;
  padding: 0 30px;
  justify-content: space-evenly;
  flex-shrink: 0;
  gap: 50px;
}
.S4hana_P2_S2 {
  display: flex;
  padding: 64px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: center;
}
.S4hana_P2_S2_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 33.6px */
  text-transform: uppercase;
  width: 40vw;
}
.S4hana_P2_S2_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 40vw;
}
.S4hana_P2_S1Web {
  width: 35vw;
}

/* end */
/* style Third Part */
.S4hanaThirdPart {
  display: flex;
  padding: 0 30px;
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  border-top: 1px solid #00000027;
  padding-top: 56px;
  border-bottom: 1px solid #00000027;
  padding-bottom: 56px;
}
.S4hana_P3_S1 {
  display: flex;
  padding: 64px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
}
.S4hana_P3_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 33.6px */
  text-transform: uppercase;
  width: 40vw;
}
.S4hana_P3_S1_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 40vw;
}
.S4hana_P3_S1Web {
  width: 35vw;
}
/* end  */
/* style Fourth Part */
.S4hanaFourthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.S4hana_P4_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.S4hana_P4_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 806px;
}
.S4hana_P4_S3 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 56px;
}
.S4hana_P4_S3_Box_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.S4hana_P4_S3_Box {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 200px;
  padding: 10px 20px 40px 20px;
  background: linear-gradient(
    180deg,
    #ffd8be43 0%,
    rgba(255, 202, 167, 0) 100%
  );
}
.S4hana_P4_S3_Box_H3 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.S4hana_P4_S3_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  margin: 0;
}
/* end */
/* style Fifth Part */
.S4hanaFifthPart {
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  gap: 50px;
  background-image: url(../../assets/svg/Rise_P6_BackGround.svg);
  background-repeat: no-repeat;
  background-position: right;
  padding-left: 80px;
  padding-top: 70px;
}
.S4hana_P5_S1 {
  display: flex;
  flex-direction: column;
}
.S4hana_P5_S1_H4 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 27.004px */
  margin: 0;
}
.S4hana_P5_S1_H2 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  margin-top: 0;
}
.S4hana_P5_S1_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 40vw;
}
.S4hana_P5_S2 {
  display: flex;
  gap: 50px;
  padding-bottom: 100px;
}
.S4hana_P5_S2_Head {
  display: flex;
  align-items: center;
  gap: 10px;
}
.S4hana_P5_S2_Box {
  display: flex;
  flex-direction: column;
  width: 20vw;
}
.S4hana_P5_S2_Box_H3 {
  color: var(--Red, #cc1d4a);
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 27.004px */
}
.S4hana_P5_S2_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  padding-left: 10px;
  width: 18vw;
}

/* end */
/* style Sixth Part */
.S4hanaSixthPart {
  display: flex;
  padding: 60px 31px 82px 32px;
  flex-direction: column;
  align-items: center;
  background: #fbfbfb;
  margin-bottom: 56px;
}
.S4hana_P6_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.S4hana_P6_S1_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 688px;
  margin-bottom: 80px;
}
.S4hana_P6_S2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.S4hana_P6_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white, #fff);
  padding: 20px;
}
.S4hana_P6_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.S4hana_P6_S2_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 402px;
}
.S4hana_P6_S2_Box:hover {
  background: #d21d4c;
}

.S4hana_P6_S2_Box:hover .S4hana_P6_S2_Box_H3,
.S4hana_P6_S2_Box:hover .S4hana_P6_S2_Box_P {
  color: #fff;
}
.S4hana_P2_S1Mobile {
  display: none;
}
.S4hana_P3_S1Mobile {
  display: none;
}

/* end */
@media (max-width: 475px) {
  .S4hanaFirstPart {
    height: auto;
    padding: 30px;
  }
  .S4hana_P1_H1 {
    font-size: 24px;
  }
  .S4hana_P1_H3 {
    font-size: 18px;
    text-align: center;
  }
  .S4hanaSecondPart {
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;
  }
  .S4hana_P2_S1Mobile {
    display: block;
    width: 100%;
  }
  .S4hana_P2_S1Web {
    display: none;
  }
  .S4hana_P2_S2 {
    padding: 8px 24px;
    flex-direction: column;
    align-items: center;
  }
  .S4hana_P2_S2_H1 {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .S4hana_P2_S2_P {
    width: 324px;
    font-size: 16px;
    text-align: center;
  }
  .S4hanaThirdPart {
    flex-direction: column;
  }
  .S4hana_P3_S1Mobile {
    display: block;
    width: 100%;
  }
  .S4hana_P3_S1Web {
    display: none;
  }
  .S4hana_P3_S1 {
    padding: 8px 24px;
    flex-direction: column;
    align-items: center;
  }
  .S4hana_P3_S1_H1 {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .S4hana_P3_S1_P {
    width: 324px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0;
  }
  .S4hana_P3_S2 {
    display: none;
  }
  .S4hana_P4_S1_H1 {
    font-size: 18px;
    text-align: center;
  }
  .S4hana_P4_S1_P {
    width: 324px;
    font-size: 16px;
    text-align: center;
  }
  .S4hana_P4_S3_Box_Container {
    flex-direction: column;
  }
  .S4hana_P4_S3_Box {
    width: 100%;
    height: 200px;
    padding: 10px;
  }
  .S4hana_P4_S3_Box_H3 {
    font-size: 18px;
    text-align: center;
  }
  .S4hana_P4_S3_Box_P {
    width: 324px;
    font-size: 16px;
    text-align: center;
  }
  .S4hanaFifthPart {
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .S4hana_P5_S1 {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .S4hana_P5_S1_H4 {
    font-size: 18px;
    text-align: center;
  }
  .S4hana_P5_S1_H2 {
    font-size: 22px;
    text-align: center;
  }
  .S4hana_P5_S1_P {
    width: 324px;
    font-size: 16px;
    text-align: center;
  }
  .S4hana_P5_S2_Box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .S4hana_P5_S2_Box_P {
    font-size: 14px;
    width: 324px;
  }
  .S4hana_P5_S2 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 0;
  }
  .S4hanaSixthPart {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    background: #fbfbfb;
    margin-bottom: 56px;
  }
  .S4hana_P6_S1_H1 {
    font-size: 20px;
  }
  .S4hana_P6_S1_P {
    font-size: 16px;
    width: 324px;
    margin-bottom: 20px;
  }
  .S4hana_P6_S2_Box_H3 {
    font-size: 20px;
  }
  .S4hana_P6_S2_Box_P {
    font-size: 16px;
    width: 324px;
  }
}
