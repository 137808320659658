.Slides_Container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.slide {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns with equal width */
  grid-template-rows: repeat(1, 1fr); /* 2 rows with equal height */
  gap: 15px; /* Optional gap between grid items */
}

.boxinfo {
  background: #fbfbfb;
  width: 300px;
  height: 200px;
  padding: 0 15px;
}

.boxinfo h1 {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 20px;
}

.boxinfo p {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 300px;
}

/*controls have 2 arrow button */
.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.arrowslide {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 85%;
  justify-content: space-between;
}

.activation {
  display: flex;
  gap: 3px;
}
