/* style of submenu services */
/* style for all container */
.submenu_partnersMain {
  display: flex;
  flex-direction: column;
  background: #17060b;
  box-shadow: 0px 8px 20px 0px rgba(30, 13, 18, 0.25);
  position: absolute;
  top: 50;
  left: 0;
  z-index: 100000000000;
  width: 100%;
  max-width: 100%;
  height: 60vh;
}
.submenu_contain_partners {
  display: flex;
  align-items: flex-start;
  color: #fff;
  padding: 48px;
  gap: 50px;
}
.summenu_partners_secondPart {
  display: flex;
  gap: 24px;
}
/* end style of all container */
/* style of info */
.submenu_info_partners {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.submenu_partners_first {
  display: flex;
  align-items: flex-start;
  color: #fff;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  margin-top: 0;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.submenu_partners_second {
  align-self: stretch;
  color: #d21d4c;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
}
.submenu_partners_third {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

/* style of pages */

.submenu_partners_contain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.submenu_partners_contain a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

.submenu_partners_contain a:hover {
  border-bottom: 1px solid #cc1d4a;
  background: #1e0d12;
}

.submenu_partners_link {
  display: flex;
  width: 40vw;
  padding: 16px 9px 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

/*  */
.SubMenu_Pho_photo {
  width: 250px;
  margin-top: 50px;
}
.SubMenu_Aws_photo {
  width: 220px;
}
.SubMenu_Sap_photo {
  width: 250px;
  margin-top: 50px;
}
.SubMenu_Azure_photo {
  width: 220px;
}
.SubMenu_Trend_photo {
  width: 250px;
  margin-top: 50px;
}
.SubMenu_Odoo_photo {
  width: 250px;
  margin-top: 50px;
}
