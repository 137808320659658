.SapRiseContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;
  overflow: hidden;
}

/* style First Part */
.RiseFirstPart {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 300px;
  padding: 56px 80px 32px 80px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex-shrink: 0;
  position: relative;
}
.Sap_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.Rise_P1_S1 {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: -30px;
}
.Rise_P1_S1 a,
.Rise_P1_S1 a:visited,
.Rise_P1_S1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.Rise_P1_S1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
}
.KeyboardArrowLeftIcon {
  color: #fff;
}
.Rise_P1_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Rise_P1_H1 {
  color: var(--white, #fff);
  font-family: "Galifex";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
}
.Rise_P1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}
@media (max-width: 475px) {
  .RiseFirstPart {
    padding: 30px;
  }
  .Rise_P1_H3 {
    text-align: center;
    font-size: 20px;
  }
}
/* end */
/* style Second Part */
.RiseSecondPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  background-image: url(../../assets/svg/Rise_P2_BackGround.svg);
  background-repeat: no-repeat;
  background-position: right;
}
.Rise_P2_S1_H1 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 33.6px */
  text-transform: uppercase;
}
.Rise_P2_S2_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 800px;
}
.Rise_P2_S3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 50px 200px; /* gap: 300px; */
}

.Rise_P2_S3_Box_H3 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Rise_P2_S3_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 450px;
}
@media (max-width: 475px) {
  .RiseSecondPart {
    background-position: 0px 500px;
  }
  .Rise_P2_S1_H1 {
    width: 350px;
    text-align: center;
  }
  .Rise_P2_S2_P {
    width: 350px;
  }
  .Rise_P2_S3 {
    padding: 20px;
  }
  .Rise_P2_S3_Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Rise_P2_S3_Box_H3 {
    width: 350px;
    text-align: center;
  }
  .Rise_P2_S3_Box_P {
    width: 350px;
    text-align: center;
  }
}
/* end */
/* Style Third Part */
.RiseThirdPart {
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}
.Rise_P3_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Rise_P3_S2_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 806px;
  margin-bottom: 100px;
}
.Rise_P3_S3 {
  margin-bottom: 50px;
}
@media (max-width: 475px) {
  .Rise_P3_S1_H1 {
    text-align: center;
    font-size: 24px;
  }
  .Rise_P3_S2_P {
    width: 350px;
    text-align: center;
  }
  .Rise_P3_S3 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* end */
/* style Fourth Part */
.RiseFourthPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
  padding-bottom: 56px;
}
.Rise_P4_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 607px;
}
.Rise_P4_S1_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 750px;
  margin: 0;
}
.Rise_P4_S2Mobile {
  display: none;
}
@media (max-width: 475px) {
  .RiseFourthPart {
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }
  .Rise_P4_S1_H1 {
    width: 350px;
    font-size: 24px;
    text-align: center;
  }
  .Rise_P4_S1_P {
    width: 350px;
    text-align: center;
  }
  .Rise_P4_S2Web {
    display: none;
  }
  .Rise_P4_S2Mobile {
    display: block;
    width: 350px;
    margin: 32px 0;
  }
}
/* end */
/* style Fifth Part */
.RiseFifthPart {
  display: flex;
  padding: 60px 50px 100px 100px;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 209, 97, 0.4);
}
.Rise_P5_S2_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 821px;
}
.Rise_P5_S2_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 780px;
  margin: 0;
}
.Rise_P5_S1Mobile {
  display: none;
}
@media (max-width: 475px) {
  .RiseFifthPart {
    padding: 20px;
    flex-direction: column;
  }
  .Rise_P5_S1Mobile {
    display: block;
    width: 300px;
  }
  .Rise_P5_S1Web {
    display: none;
  }
  .Rise_P5_S2_H1 {
    width: 350px;
    font-size: 24px;
    text-align: center;
  }
  .Rise_P5_S2_P {
    width: 350px;
    font-size: 18px;
    text-align: center;
  }
}
/* end */
/* style Sixth Part */
.RiseSixthPart {
  background: #fbfbfb;
  padding: 50px 100px;
  background-image: url(../../assets/svg/Rise_P6_BackGround.svg);
  background-repeat: no-repeat;
  background-position: right;
}

.Rise_P6_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Rise_P6_S1_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
.Rise_P6_S1_H3 {
  color: #4d4346;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  letter-spacing: -0.24px;
}
@media (max-width: 475px) {
  .RiseSixthPart {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
  }
  .Rise_P6_S1_H1 {
    text-align: center;
  }
  .Rise_P6_S1_H3 {
    text-align: center;
  }
  .Rise_P6_S1_P {
    text-align: center;
  }
}
/* end */
/* style Seventh part */
.RiseSeventhPart {
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    #ffd8be43 0%,
    rgba(255, 202, 167, 0) 100%
  );
  height: 500px;
  /* #FFFFFF, #FFE5D381, #FFD8BE43, #FFCAA700, #FFCAA700 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px;
  gap: 50px;
}
.Rise_P7_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Rise_P7_S2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Rise_P7_S2 a,
.Rise_P7_S2 a:active,
.Rise_P7_S2 a:visited {
  text-decoration: none;
}
.Rise_P7_S2_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  padding: 10px 20px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: var(--white, #fff);
  /* for text */
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 27.004px */
}

.Rise_P7_S2_button:hover {
  background: #d21d4c;
  color: #fff;
}
@media (max-width: 475px) {
  .RiseSeventhPart {
    margin: 0;
    height: auto;
    justify-content: center;
    padding: 20px;
    gap: 50px;
  }
  .Rise_P7_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .Rise_P7_S2_button {
    width: 350px;
  }
}
