.HomeContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  overflow: hidden;
}
@media (max-width: 475px) {
  .HomeContainer {
    gap: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
}

.HomeContainer a,
.HomeContainer a:active,
.HomeContainer a:visited {
  text-decoration: none;
}
.HomePartTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: -100px;
}
.Home_P2_Main {
  display: flex;
  padding: 0px 40px 0px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.Home_P2_First {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.Home_P2_S1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 630px;
}
.Home_P2_First_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Home_P2_First_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 630px;
}

.Home_P2_S1_button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #d41e4d;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border: 1px solid #d41e4d;
  background: transparent;
  cursor: pointer;
}
/*  */
@media (max-width: 475px) {
  .HomePartTwo {
    padding: 0 30px;
    margin-top: 70px;
  }

  .Home_P2_Main {
    flex-direction: column;
    padding: 0;
  }
  .Home_P2_S1 {
    justify-content: center;
    width: auto;
  }
  .Home_P2_First {
    align-items: center;
  }
  .Home_P2_First_H1 {
    font-size: 20px;
    text-align: center;
    width: 306px;
  }
  .Home_P2_First_P {
    font-size: 14px;
    width: 350px;
  }
  .HomeP2Dot {
    display: none;
  }
  .HomeP2Svg {
    margin-top: 30px;
    width: 330px;
  }
}
/* part 3 have his own style */
/* style of part 4 */
.HomePartFour {
  display: flex;
  padding: 48px 80px;
  align-items: flex-start;
  gap: 70px;
}
.HomeP4_S1 {
  display: flex;
  padding: 0px 0px 24px 32px;
  flex-direction: column;
  align-items: flex-start;
}
.HomeP4_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.5; /* 0px */
  text-transform: uppercase;
  width: 468px;
  margin-top: 0;
}
.HomeP4_S1_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 468px;
}
.HomeP4_S2_Container {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  border: 1px solid #e5d6da;
  background: #f7f6f6;
  width: 700px;
}
.HomeSpace {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* style mobile part four */
@media (max-width: 475px) {
  .HomePartFour {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .HomeP4_S1 {
    align-items: center;
    padding: 0;
  }
  .HomeP4_S1_H1 {
    text-align: center;
    font-size: 20px;
    width: 324px;
  }
  .HomeP4_S1_P {
    text-align: center;
    font-size: 16px;
    width: 350px;
  }
  .HomeP4_S2_Container {
    width: 100%;
  }
}

/* style Home Part Five */
.HomePartFive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
.Home_P5_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

/* style mobile part five */
@media (max-width: 475px) {
  .HomePartFive {
    gap: 30px;
  }
  .Home_P5_H1 {
    font-size: 20px;
  }
}
/* Home Part Six */

.HomePartSix {
  display: flex;
  padding: 0px 80px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}

.Home_P6_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
}
.Home_P6_S2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}
.Home_P6_S2_Element {
  display: flex;
  width: 210px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.HomeP6S2T {
  font-family: "Gotham" sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(138deg, #f89a5b 1.54%, #d41e4d 95.98%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.HomeP6S2Img {
  width: 70px;
  padding-top: 20px;
}
.HomeP6S2Img120 {
  width: 90px;
  padding-top: 20px;
}
.HomeP6S2H3 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
  text-align: center;
}

/* end */
/* style mobile part six */
@media (max-width: 475px) {
  .HomePartSix {
    padding: 0px 16px;
    gap: 32px;
  }
  .Home_P6_S1_H1 {
    font-size: 20px;
  }
  .Home_P6_S2 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    align-self: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .Home_P6_S2_Element {
    display: flex;
    width: 150px;
    padding: 16px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
  }
  .HomeP6S2Img {
    width: 50px;
    padding-top: 0px;
  }
  .HomeP6S2Img120 {
    width: 60px;
    padding-top: 20px;
  }
  .HomeP6S2H3 {
    font-size: 16px;
  }
  .HomeP6S2Arrow {
    display: none;
  }
  .Home_P6_S2_ArrowHide {
    display: none;
  }
}

/* Home Part Seven */
.HomePartSeven {
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #d41e4d;
  position: relative;
}
.Home_P7_S1 {
  display: flex;
  padding: 32px 32px 32px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}
.Home_P7_S1_H1 {
  color: #fff;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 700px;
}
.Home_P7_S1_P {
  color: #fff;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 600px;
}
.Home_P7_S1_Button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  /* For text */
  color: #fff;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  background: transparent;
  cursor: pointer;
}
.Home_P7_S2 {
  display: flex;
  align-items: center;
}
.HomeP7Img {
  width: 680px;
}

.HomePartSeven:hover .HomeP7Img {
  transform: scale(1.1);
}
.HomePartSeven:hover .Home_P7_S1_Button {
  background: linear-gradient(96deg, #cc1d4a 0%, #df352b 100%);
}

.HomeP7Img {
  transition: transform 2s ease;
}
@media (max-width: 475px) {
  .HomePartSeven {
    flex-direction: column;
    display: flex;
    overflow: hidden;
    align-items: center;
    gap: 48px;
    background-color: #d41e4d;
    position: relative;
  }
  .Home_P7_S1 {
    display: flex;
    padding: 30px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .Home_P7_S1_H1 {
    font-size: 20px;
    text-align: center;
    width: 324px;
  }
  .Home_P7_S1_P {
    text-align: center;
    width: 100%;
    font-size: 16px;
  }
  .HomeP7Img {
    width: 100%;
  }
  .Home_P7_S1_Button {
    background: linear-gradient(96deg, #cc1d4a 0%, #df352b 100%);
  }
}
/* Home Part Eight */
.HomePartEight {
  display: flex;
  padding: 24px 0px 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  overflow: hidden;
}
.Home_P8_S1 {
  display: flex;
  align-self: center;
}
.Home_P8_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Home_P8_S2 {
  overflow: hidden;
}
@media (max-width: 475px) {
  .Home_P8_S1_H1 {
    font-size: 20px;
  }
}

.Home_Index_Container {
  overflow: hidden;
}
.Home_P8_S2 {
  margin-bottom: 50px;
}
.HomeP4Aws {
  width: 12vw;
}
.HomeP4AwsW {
  width: 8vw;
}
.ahmedahmed {
  position: absolute;
}
