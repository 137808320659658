.footer_part1 {
  display: flex;
  padding: 8px 80px;
  align-items: center;
  justify-content: space-between;
  background: #d41e4d;
  gap: 64px;
}
.background-svg {
  position: absolute;
  /* height: 230px; */
  width: 35vw;
  left: 2%;
  z-index: 1;
}
.footer_part1_first {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.part1_first_p {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
}
.part1_first_i {
  width: 40px;
  height: 40px;
}
.footer_part1_second {
  display: flex;
  justify-content: center;
  align-items: center;
}
.part1_second_input {
  height: 21px;
  padding: 10px;
  border: none;
  width: 400px;
  flex: 1 0 0;
}
.part1_second_input::placeholder {
  font-size: 16px;
}
.part1_second_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  height: 43px;
  background: #1e0d12;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
/*  */
/*  */
.footer_part_s {
  display: flex;
  padding: 1.7vw 5.7vw;
  justify-content: space-between;
  align-items: center;
  background-color: #1e0d12;
  border-bottom: 1px solid #54333c;
}
.part_s1 {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 1.85vw;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 51.2px */
  text-transform: uppercase;
}
.part_sButton {
  display: flex;
  padding: 1.13vw 1.7vw;
  align-items: center;
  justify-content: center;
  gap: 0.7vw;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fafafa;
  font-family: "Inter", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 24.804px */
  text-transform: uppercase;
  cursor: pointer;
}
.part_sIcon {
  color: #cc1d4a;
}
/*  */
/*  */

.footer_part2 {
  display: flex;
  padding: 2.85vw 5.7vw;
  padding-bottom: 2.13vw;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #1e0d12;
}
.part1,
.part2 {
  display: flex;
  width: 10vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.13vw;
  flex-shrink: 0;
  color: #fafafa;
  z-index: 999;
}
.part3 {
  display: flex;
  width: 30vw;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;
  color: #fafafa;
}
.part4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5vw;
  flex-shrink: 0;
  color: #fafafa;
}
.part1 a,
.part1 a:visited,
.part1 a:active,
.part1 a:hover,
.part2 a,
.part2 a:visited,
.part2 a:active,
.part2 a:hover,
.part3 a,
.part3 a:visited,
.part3 a:active,
.part3 a:hover,
.part4 a,
.part4 a:visited,
.part4 a:active,
.part5 a:hover {
  color: #fafafa;
  font-family: "Gotham", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.part4 a {
  font-weight: 700;
}
.part2_first_link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.355vw;
}
.Main-link {
  font-size: 1.13vw;
  font-weight: 600;
  color: #fafafa;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  line-height: normal;
}
.footer_icon {
  width: 0.71vw;
  height: 1.065vw;
}
.Footer_Part2_Address_Dubai {
  margin-top: 0;
}
/*  */
/*  */
.footer_part3 {
  border-top: 1px solid #54333c;
  display: flex;
  padding: 2vw 5.68vw;
  justify-content: space-between;
  align-items: center;
  background-color: #1e0d12;
}
.footer_part3_first {
  color: #b54f49;
  font-family: "Gotham", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%;
}
.footer_part3_second {
  display: flex;
  align-items: center;
  gap: 4.26vw;
}
.footer_social {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  gap: 0.355vw;
  font-family: "Gotham", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.social_icon {
  width: 20px;
}
.social_iconY {
  width: 2.2vw;
}
.footer_part3_second a,
.footer_part3_second a:hover,
.footer_part3_second a:visited,
.footer_part3_second a:active {
  text-decoration: none;
}
.footer_part3_h4_Web {
  margin-bottom: 0;
  background: linear-gradient(93deg, #d41e4d 5.89%, #f89a5b 115.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1vw;
}
.footer_part3_P_Web {
  margin-top: 0;
  color: #fafafa;
  font-family: "Gotham", sans-serif;
  font-size: 1vw;
  width: 30vw;
  margin-bottom: 0;
}
.footer_part3_P_Web span {
  font-weight: 700;
}
.Footer_Part2_Address_Egypt,
.Footer_Part2_Address_Dubai {
  font-family: "Gotham", sans-serif;
}
.Footer_Part2_Address_Egypt span,
.Footer_Part2_Address_Dubai span {
  font-weight: 700;
}
.KsaSN {
  margin-left: 6.3vw;
  padding-top: 0.4vw;
}
