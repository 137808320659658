.Digital_Container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Digital_Container > * {
  margin-bottom: 56px;
}
/* start style Part 1 Banner */
.Digital_FirstPart_Banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 45vh;
  padding: 56px 80px 32px 80px;
  position: relative;
}
/* .Digital_FirstPart_Banner > * {
  position: relative;
  z-index: 5;
}
.Digital_FirstPart_Banner:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
} */
.Digital_FirstPart_Banner video.DigitalBannerVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* for text P1 Banner */
.Digital_P1_H1 {
  color: var(--white, #fff);
  font-family: "Galifex", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 54.008px */
}
.Digital_P1_P {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 36.005px */
}
/* end style web P1 */
/* Mobile P1 */
@media (max-width: 475px) {
  .Digital_P1_H1 {
    text-align: center;
  }
  .Digital_P1_P {
    text-align: center;
  }
  .Digital_Container > * {
    margin-bottom: 28px;
  }
  .Digital_FirstPart_Banner {
    padding: 56px 40px 32px 40px;
    margin-bottom: 0;
  }
}
/* start style web P2 */
.Digital_SecondPart {
  display: flex;
  flex-direction: column;
  padding: 0 64px;
}
.Digital_P2_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Digital_P2_S1_Head {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.Digital_P2_S1_Head_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Digital_P2_S2 {
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #f6f6f6;
  box-shadow: 0px 6px 0px 0px #d41e4d;
}
.Digital_P2_S2_Box {
  display: flex;
  width: 25vw;
  height: 30vh;
  padding: 24px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.P2_Icon_Tree {
  width: 4vw;
  display: flex;
  align-self: center;
}
.Digital_P2_S2_Box_H3 {
  color: #d41e4d;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
  text-align: center;
}
.Digital_P2_S2_Box_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
}
@media (max-width: 475px) {
  .Digital_P2_S1_Head_H1 {
    text-align: center;
  }
  .Digital_SecondPart {
    padding: 0;
    margin-bottom: 0;
  }
  .Digital_P2_S2 {
    flex-direction: column;
    padding: 20px 0;
    gap: 40px;
    /* width: 100%; */
    box-shadow: none;
  }
  .Digital_P2_S2_Box {
    width: 100%;
    padding: 12px;
    height: 20vh;
    padding: 8px 0px;
  }
  .P2_Line_Border {
    display: none;
  }
  .P2_Icon_Tree {
    width: 12vw;
  }
  .Digital_P2_S2_Box_P {
    padding: 0px 20px;
  }
}
/* end style Web P2 */

/* start style Web P3 */
.Digital_ThirdPart {
  display: flex;
  padding: 40px 80px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  flex-shrink: 0;
  background: #34131c;
  margin-bottom: 0;
}
.Digital_P3_Head {
  color: #ff5368;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Digital_P3_Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 32px;
  flex-wrap: wrap;
}
.Digital_P3_Main_L {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}
.Digital_P3_Main_L_Part {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  width: 40vw;
  border-bottom: 1px solid #ff5368;
  gap: 16px;
  flex-shrink: 0;
}
.Digital_P3_Main_L_Part_S2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}
.Digital_P3_Main_L_Part_S1 {
  width: 4vw;
}
.Digital_P3_Main_L_Part_S2_H3 {
  color: #ff5368;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
}
.Digital_P3_Main_L_Part_S2_P {
  color: var(--white, #fff);
  font-family: Gotham;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
}
/* end style Web P3 */
/* Mobile P3 */
@media (max-width: 475px) {
  .Digital_P3_Head {
    text-align: center;
  }
  .Digital_P3_Main_L {
    align-items: center;
    flex-direction: column;
  }
  .Digital_P3_Main_L_Part {
    align-items: flex-start;
    width: 90vw;
  }
  .Digital_P3_Main_L_Part_S1 {
    width: 8vw;
  }
  .Digital_P3_Main_L_Part_S2_H3 {
    margin-top: 3px;
  }
}

/* start style Web P4 */
.Digital_FourthPart {
  display: flex;
  position: relative;
  box-sizing: border-box;
  align-items: stretch;
  background: #880e2d;
}
.Digital_P4_Polygon_Left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 7vw;
  z-index: 2;
}
.Digital_P4_Polygon_LeftUp {
  position: absolute;
  left: 0;
  bottom: 1vh;
  z-index: 1;
}
.Digital_P4_Polygon_Right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 6vw;
}
.Digital_P4_S1 {
  padding: 48px 80px 20px 60px;
  gap: 48px;
  width: 100vw;
}
.Digital_P4_S1_Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.Digital_P4_S2 {
  position: relative;
}
.Digital_P4_S2_Photo {
  margin-bottom: -4px;
}
.Digital_P4_S1_H1 {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Digital_P4_S1_Ul {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.Digital_P4_S1_Li {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 27px */
}

.Digital_P4_S1_Li strong {
  text-transform: uppercase;
}
/* end style Web P4 */
/* Mobile P4 */
@media (max-width: 475px) {
  .Digital_P4_Polygon_Left,
  .Digital_P4_Polygon_LeftUp,
  .Digital_P4_Polygon_Right {
    display: none;
  }
  .Digital_FourthPart {
    flex-direction: column;
    gap: 24px;
  }
  .Digital_P4_S2_Photo {
    width: 100vw;
    margin-bottom: -4px;
  }
  .Digital_P4_S1 {
    padding: 0;
    gap: 48px;
    width: 100vw;
  }
  .Digital_P4_S1_H1 {
    font-size: 28px;
    width: 100vw;
    text-align: center;
  }
  .Digital_P4_S1_Li {
    font-size: 18px;
  }
}

/* start style Web P5 */

.Digital_FifthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.Digital_P5_Head {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 43.2px */
  text-transform: uppercase;
}
.Digital_P5_S2 {
  display: flex;
  width: 100%;
}
.Digital_P5_S2_Left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Digital_P5_S2_Right {
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}
.Digital_P5_S2RightPhoto,
.Digital_P5_S2LeftPhoto {
  width: 49.5vw;
}
.Digital_P5_S2_H2 {
  position: absolute;
  color: var(--white, #fff);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 32.4px */
  text-transform: uppercase;
  width: 30vw;
}
.Digital_P5_S3 {
  display: flex;
  padding: 48px 80px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.Digital_P5_S3_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  line-height: 135%; /* 27px */
}
.Digital_P5_S5 {
  display: flex;
  padding: 40px 80px;
  gap: 50px;
}
.Digital_P5_S5_H3 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 43.2px */
  text-transform: uppercase;
  width: 30vw;
}
.Digital_P5_S5_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  width: 40vw;
}
/* #F89A5B, #D41E4D, #1E0D12 */
.Digital_P5_S5_Span {
  padding: 2px;
  background: #d41e4c1c;
  color: #d83905;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
/*  */
/*  */
@media (max-width: 475px) {
  .Digital_P5_S2 {
    flex-direction: column;
    width: 100vw;
  }
  .Digital_P5_S2RightPhoto,
  .Digital_P5_S2LeftPhoto {
    width: 100vw;
  }
  .Digital_P5_S2_H2 {
    position: absolute;
    color: var(--white, #fff);
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 32.4px */
    text-transform: uppercase;
    width: 80vw;
  }
  .Digital_P5_Head {
    font-size: 28px;
    padding: 20px 0px;
  }
  .Digital_P5_S3 {
    padding: 0;
  }
  .Digital_P5_S3_P {
    width: 77vw;
  }
  .Digital_P5_S5 {
    flex-direction: column;
    padding: 0;
    gap: 20px;
  }
  .Digital_P5_S5_H3 {
    width: 90vw;
    text-align: center;
    font-size: 24px;
  }
  .Digital_P5_S5_P {
    width: 90vw;
    text-align: center;
    font-size: 18px;
  }
}
/*  */
.Digital_SixthPart {
  display: flex;
  padding: 64px 80px 84px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 56px;
}
.Digital_P6_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 43.2px */
  text-transform: uppercase;
}
.Digital_P6_S2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.Digital_P6_S2_Box {
  display: flex;
  width: 22vw;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.Digital_P6_S2_Box_S1 {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.Digital_P6_S2_Box_S1_H3 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 135%; /* 32.4px */
  text-transform: uppercase;
  margin-top: 0;
}
.Digital_P6_box_Border {
  width: 0.5vw;
}
.Digital_P6_box_Border3 {
  width: 0.5vw;
}
.P6_Line_Border {
  height: 16vw;
}
.Digital_P6_S2_Box_S2,
.Digital_P6_S2_Box_S2_Box3 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}

/*  */
/*  */
@media (max-width: 475px) {
  .Digital_SixthPart {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 56px;
  }
  .Digital_P6_S1_H1 {
    text-align: center;
    font-size: 24px;
  }
  .Digital_P6_S2 {
    flex-direction: column;
  }
  .Digital_P6_S2_Box {
    width: 100vw;
    text-align: center;
  }
  .Digital_P6_S2_Box_S1_H3 {
    margin-top: -5px;
    text-align: center;
  }
  .Digital_P6_box_Border,
  .Digital_P6_box_Border3 {
    width: 1vw;
  }
  .Digital_P6_S2_Box_S2,
  .Digital_P6_S2_Box_S2_Box3 {
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 28px */
    width: 70vw;
    text-align: center;
  }
  .Digital_P6_S2_Box {
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    align-self: normal;
  }
}

/*  */

.Digital_SeventhPart {
  display: flex;
  height: 48vh;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
  flex-shrink: 0;
  background-color: #d41e4d;
  margin-bottom: 0;
  box-sizing: border-box;
}
.Digital_P7_S1 {
  display: flex;
  flex-direction: column;
  padding: 40px 32px 32px 80px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.Digital_P7_S1_H1 {
  width: 50vw;
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Digital_P7_S2 {
  height: 100%;
}
.Digital_P7_S2Photo {
  width: 100%;
  height: 100%;
}
.Digital_P7_S1_Button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--white, #fff);
  background: transparent;
  cursor: pointer;
  /* for text */
  color: var(--white, #fff);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 475px) {
  .Digital_SeventhPart {
    flex-direction: column;
    height: auto;
  }
  .Digital_P7_S1 {
    padding: 10px;
    align-items: center;
  }
  .Digital_P7_S1_H1 {
    width: 90vw;
    font-size: 24px;
    text-align: center;
  }
  .Digital_P7_S2Photo {
    margin-bottom: -3px;
    display: none;
  }
}
