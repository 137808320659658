.HeaderMobile_Container {
  display: flex;
  width: auto;
  height: 65px;
  padding: 8px 30px 0px 30px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-top: 4px solid #d62344;
  background: #1e0d12;
  border-bottom: none;
  overflow: hidden;
  position: relative;
}
.LogoMobile {
  width: 120px;
}
.Button_Open_Icon {
  background: transparent;
  border: none;
}
.SubmenusMobile_Container {
  background: #1e0d12;
  border-bottom: none;
  display: flex;
  padding: 8px 30px 48px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  z-index: 10000;
  width: 100%;
  box-sizing: border-box;
  top: 65px;
}
.headerMobile_nav_a {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  align-self: stretch;
}
.headerMobile_nav_a a,
.headerMobile_nav_a a:visited,
.headerMobile_nav_a a:active {
  text-decoration: none;
  color: #fafafa;
}
.headerMobile_nav_p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fafafa;
  margin: 0;
}
.headerMobile_nav_icon {
  width: 25px;
  height: 25px;
  transition: transform 0.5s ease;
}
.rotate-arrow {
  transform: rotate(180deg);
}
.headerMobile_nav_Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--white, #fff);
  align-items: flex-start;
  color: #fafafa;
  padding: 16px 0px 16px 0px;
}
