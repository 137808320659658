.devOpsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  overflow: hidden;
}
.devOps_FirstPart {
  background-image: url(../../assets/svg/banner_devops.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 40px 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.devOps_FirstPartCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.devOps_FirstPartCon h3 {
  color: #fff;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.devOps_FirstPartCon p {
  width: 631px;
  color: #fff;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
.devOps_FirstPartConH1 {
  background: linear-gradient(91deg, #cc1d4a -3.5%, #f89a5b 119.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.devOps_FirstPartConH1 h1 {
  font-family: "Galifex", Arial, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
/* end style for part 1 */
@media (max-width: 475px) {
  .devOpsContainer {
    gap: 46px;
  }
  .devOps_FirstPart {
    background-image: url(../../assets/images/banner_devopsMobile.png);
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    padding-top: 10px;
    height: 500px;
  }
  .devOps_FirstPartCon {
    align-items: center;
    margin-top: 3vh;
    gap: 0;
  }
  .devOps_FirstPartCon h3 {
    font-size: 16px;
    margin: 0;
  }
  .devOps_FirstPartConH1 h1 {
    font-size: 24px;
  }
  .devOps_FirstPartCon p {
    width: 100%;
    padding: 30px;
    font-size: 16px;
    text-align: center;
    margin: 0;
    display: none;
  }
}
/* style for part 2 */
.devOps_secondPart_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
}
.devOps_P2_Head {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.devOps_P2_Content {
  display: flex;
  width: 1280px;
  height: 490px;
  padding-right: 0px;
  align-items: flex-start;
  gap: 64px;
  flex-shrink: 0;
}
.CareersSpace {
  display: flex;
  justify-content: center;
  align-items: center;
  stroke-width: 1px;
  stroke: #d8d8d8;
}
/* end style for part 2 */
@media (max-width: 475px) {
  .devOps_P2_Head {
    font-size: 20px;
  }
  .devOps_P2_Content {
    width: 100%;
    height: 100%;
  }
}

/* style for part 3 */
.devOps_ThirdPart {
  display: flex;
  padding: 0px 80px;
  justify-content: space-between;
  align-items: flex-start;
}

.devOps_thirdPart_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
}
.devOps_P3_first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
}
.devOps_P3_first h1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.devOps_P3_first p {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 600px;
}
/* end style for part 3 */
@media (max-width: 475px) {
  .devOps_P3_first {
    gap: 10px;
  }
  .devOps_P3_first h1 {
    font-size: 20px;
  }
  .devOps_P3_first p {
    font-size: 14px;
    text-align: center;
    width: 324px;
  }
}

/* style for part 4 */
.devOps_PartFour {
  display: flex;
  padding: 0px 80px;
  flex-direction: column;
  align-items: center;
  gap: 68px;
}
.devOps_P4_first {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.devOps_P4_first h1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 30.005px */

  text-transform: uppercase;
}
.devOps_P4_first p {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 700px;
  line-height: 150.023%; /* 30.005px */
}
.devOps_P4_second {
  display: flex;
  padding: 0px 163px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
.devOps_P4_second_Main {
  display: flex;
  padding: 0px 40px 40px 40px;
  justify-content: center;
  align-items: center;
  gap: 170px;
}
.devOps_P4_second_Main_Back {
  display: flex;
  padding: 0px 40px 40px 40px;
  justify-content: center;
  align-items: center;
  gap: 170px;
  position: relative;
}
.DevOpsBackNumber {
  position: absolute;
  left: -25px;
  bottom: 10px;
}
.devOps_P4_second_MainPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.devOps_P4_second_MainPart p {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  width: 200px;
}
/* end style for part 4 */
@media (max-width: 475px) {
  .devOps_P4_first h1 {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .devOps_P4_second_MainPart p {
    font-size: 18px;
    width: 100%;
  }
  .devOps_P4_first p {
    font-size: 16px;
    width: 324px;
    margin: 0;
  }
  .devOps_P4_second {
    width: 324px;
    padding: 0;
    gap: 10px;
  }

  .devOps_P4_second_Main,
  .devOps_P4_second_Main_Back {
    padding: 0;
    flex-direction: column;
    gap: 30px;
  }
}
/* style for part 5 */
.devOps_PartFive {
  display: flex;
  padding: 0px 80px;
  gap: 100px;
  justify-content: space-between;
  align-items: flex-start;
}
.devOps_P5_first {
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* gap: 40px; */
  align-self: stretch;
}
.devOps_P5_first h1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.devOps_P5_first p {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 593.422px;
}
.DevOpsDeliverMobile {
  display: none;
}
.DevOpsDeliver {
  width: 600px;
  display: flex;
  border-radius: 10px;
}
@media (max-width: 475px) {
  .devOps_PartFive {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    gap: 0px;
  }
  .devOps_P5_first {
    align-items: center;
    padding-bottom: 0;
  }
  .devOps_P5_first h1 {
    font-size: 20px;
  }
  .devOps_P5_first p {
    font-size: 18px;
    width: 324px;
    text-align: center;
    margin-bottom: 40px;
  }
  .DevOpsDeliver {
    display: none;
  }
  .DevOpsDeliverMobile {
    width: 100%;
    height: 100%;
    padding: 15px;
    border-radius: 10px;
    display: block;
  }
  .devOpsContainer {
    overflow: hidden;
  }
  .DevOpsBackNumber {
    display: none;
  }
}
