.container {
  overflow: hidden;
}
/* style contact-us page part 1 */
.contact_part1 {
  background-image: url("./banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 42px;
}

.contact_part1_contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.contact_part1_first p {
  font-family: "Galifex", Arial, sans-serif;
  font-size: 32px;
  font-style: normal;
  letter-spacing: -1px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(91deg, #cc1d4a 1%, #f89a5b 15%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.contact_part1_second {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
}
.contact_part1_third {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  max-width: 750px;
}
/* End  style contact-us page part 1 */
/* Start style contact-us page part 2 */
.contact_part2 {
  display: flex;
  padding: 40px 80px 0px 80px;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 48px;
}
.contact_part2_second {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 30px;
}

.con_part2_Input1 {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

.input {
  position: relative;
  display: inline-block;
  border-top: 1px solid #9d9d9d;
  padding-top: 0px;
}

.input_1::placeholder {
  font-size: 12px;
  text-transform: lowercase;
}

.input__field {
  width: 300px; /* here to width total input */
  padding: 15px;
  border: 1px solid #9d9d9d;
  border-top: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}

.input__label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  padding: 0 4px;
  font-size: 14px;
  color: #9d9d9d;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
/* input 3 for country and phone number */
.con_part2_Input2 {
  margin-bottom: 20px;
}
input::-webkit-input-placeholder {
  color: #9d9d9d;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.contact_part2_third {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-top: 30px;
}
.contact_part2_third_s1 {
  display: flex;
  width: 670px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.solutionQuestion {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}
.Contact_submitButton {
  width: 670px;
  display: flex;
  padding: 12px 18px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.contact_part2_third_s2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ababab;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  padding: 50px 0;
}
/* End style contact-us page part 2 */
/*  */

/* Start style contact-us page part 3 */
.contact_part3 {
  display: flex;
  padding: 40px 80px 0px 80px;
  flex-direction: column;
  gap: 48px;
}
.contact_big_head {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%; /* 48px */
  text-transform: uppercase;
  border-bottom: 2px solid #ededed;
  text-align: center;
}
.contact_part3_second {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin: 50px 0 100px 0;
}
.con_part3_second_info {
  width: 20vw;
}
.con_part3_second_info,
.con_part3_second_infoC {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.con_info_icon {
  width: 24px;
  height: 24px;
}
.SpaceBetweenMobile {
  width: 1vw;
}
.part3_second_info1_p_bold {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 155.023%;
  margin-bottom: 10px;
}
.part3_second_info1_p {
  display: flex;
  justify-content: center;
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135.023%;
}
.part3_second_info1_p span {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 155.023%; */
}
.Contact_Info1_h4 {
  background: linear-gradient(93deg, #d41e4d 5.89%, #f89a5b 115.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.part3_second_info_liveMap a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.part3_second_info_liveMap a,
.part3_second_info_liveMap a:visited,
.part3_second_info_liveMap a:active {
  color: #cc1d4a;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 24.804px */
  text-decoration-line: underline;
}
.info_Mail,
.info_Mail:visited,
.info_Mail:active {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 24.804px */
  text-decoration-line: underline;
  margin-top: 10px;
}
.contact_part3_third {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 100px;
}
.contact_part3_third_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}
.social_icon {
  width: 20px;
}
.contact_part3_social {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  color: #cc1d4a;
  font-family: "Gotham", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact_part3_third_icon a,
.contact_part3_third_icon a:hover,
.contact_part3_third_icon a:visited,
.contact_part3_third_icon a:active {
  text-decoration: none;
}
.con_icon {
  stroke: #cc1d4a;
}
@media (max-width: 475px) {
  .contact_big_head {
    font-size: 20px;
  }
  .con_part2_Input1 {
    flex-direction: column;
    gap: 20px;
  }
  .input__field {
    width: 250px;
  }
  .contact_part2_third_s1 {
    align-items: center;
  }
  .solutionQuestion {
    font-size: 16px;
    text-align: center;
  }
  .Contact_submitButton {
    width: 200px;
  }
  .contact_part3_second {
    flex-direction: column;
    margin: 0;
    align-self: center;
    text-align: center;
  }
  .SpaceBetween {
    display: none;
  }
  .con_part3_second_info {
    border-bottom: 2px solid #ededed;
    width: 100%;
    padding: 20px 0;
  }
  .part3_second_info1_p {
    justify-content: center;
    align-self: center;
    gap: 10px;
    width: 300px;
  }

  .LastOne {
    display: flex;
    align-self: center;
  }
  .contact_part3_third_icon {
    width: auto;
    gap: 20px;
  }
  .contact_part3_social {
  }
  .Contact_Info1_h4 {
    font-size: large;
  }
  .SpaceBetweenMobile {
    display: none;
  }
}

.ReCaptcha_Contact {
  display: flex;
  /* background-color: #1e0d12; */
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}
