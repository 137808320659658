/* style of submenu services */
/* style for all container */
.submenu_servicesMain {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  background: #17060b;
  box-shadow: 0px 8px 20px 0px rgba(30, 13, 18, 0.25);
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 50;
  left: 0;
  z-index: 100000000000;
  justify-content: flex-start;
  height: 60vh;
}

.submenu_contain_services {
  display: flex;
  align-items: center;
  gap: 48px;
  align-self: stretch;
  color: #fff;
  padding: 48px;
  align-items: flex-start;
}
/* end style of all container */
/* style of info */
.submenu_info {
  display: flex;
  flex: 1;
  padding: 64px 0px;
  padding-top: 0;
  flex-direction: column;
  align-items: flex-start;
}
.submenu_services {
  color: #fff;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-top: 0;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.submenu_discover {
  align-self: stretch;
  color: #d21d4c;
  font-family: "Galifex", Arial, sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 24.004px */
}
.submenu_help {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 30vw;
}

/* style of pages */

.submenu_services_contain {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
}

.submenu_services_contain a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

.submenu_services_contain a:hover {
  border-bottom: 1px solid #cc1d4a;
  background: #1e0d12;
}
.submenu_pages2 {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.submenu_services_link {
  display: flex;
  padding: 16px 9px 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  color: #fff;

  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 25vw;
}

/*  */
.submenu_pages2 a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
}

.submenu_pages2 a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
