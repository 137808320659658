.TrendMicro_Container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 26px;
}
@media (max-width: 475px) {
  .body {
    display: flex;
    flex-direction: column;
    gap: 56px;
    overflow-x: hidden;
  }
}
.Trend_FirstPart {
  /* background-image: url(../../assets/svg/sap_banner.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.Trend_P1_P1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -10vh;
}
.Trend_P1_P1_H1 {
  color: #fff;
  font-family: "Galifex", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150.023%;
}
.Trend_FirstPart_H1 {
  color: #fff;
  font-family: "Galifex", Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-top: 0;
}
.Trend_FirstPart_P {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 880px;
  margin: 0;
}
.TrendLogo {
  width: 200px;
}
.SapStar {
  display: none;
}
@media (max-width: 475px) {
  .TrendMicro_Container {
    align-items: center;
    gap: 32px;
  }
  .Trend_FirstPart {
    background-image: url(../../assets/images/sap_bannerMobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }
  .SapStar {
    display: block;
    margin-left: 30px;
    display: none;
  }
  .Trend_P1_P1_H1 {
    font-size: 20px;
  }
  .Trend_FirstPart_H1 {
    font-size: 20px;
    text-align: center;
  }
  .Trend_FirstPart_P {
    font-size: 16px;
    width: 324px;
    display: none;
  }
  .TrendLogo {
    width: 150px;
  }
}
/* End style First Part */
/* begin to responsive*/
/* style second Part */
.Trend_SecondPart {
  display: flex;
  padding: 56px;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.Trend_P2_S1 {
  display: flex;
  width: 60vw;
  flex-direction: column;
  align-items: flex-start;
}
.Trend_P2_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 45vw;
}
.Trend_P2_S1_P {
  color: #515151;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  width: 45vw;
}
.Trend_P2_S2 {
  width: 450px;
  /* border-radius: 10px; */
}
/* end */
@media (max-width: 475px) {
  .Trend_SecondPart {
    display: flex;
    padding: 0px 0px;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }
  .Trend_P2_S2 {
    display: none;
  }
  .Trend_P2_S1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }
  .Trend_P2_S1_H1 {
    text-align: center;
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    width: 90vw;
  }
  .Trend_P2_S1_P {
    text-align: center;
    color: #515151;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    width: 360px;
  }
}
/* style Third Part */
.Trend_ThirdPart {
  display: flex;
  padding: 56px 50px;
  align-items: center;
  justify-content: space-between;
  gap: 100px;
}
.Trend_P3_S2 {
  display: flex;
  width: 65vw;
  flex-direction: column;
  align-items: flex-start;
}
.Trend_P3_S2_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Trend_P3_S2_P {
  color: #515151;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  width: 45vw;
}
.Trend_P3_S1 {
  width: 35vw;
}
/* end */
/* begin Section Three Responsive */
@media (max-width: 475px) {
  .Trend_P3_S1 {
    display: none;
  }
  .Trend_ThirdPart {
    display: flex;
    padding: 0px 0px;
    align-items: center;
    justify-content: center;
  }
  .Trend_P3_S2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }
  .Trend_P3_S2_H1 {
    text-align: center;
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .Trend_P3_S2_P {
    text-align: center;
    color: #515151;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    width: 360px;
  }
}
/* style Fourth Part */
.Trend_FourthPart {
  display: flex;
  padding: 56px 116px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  background: #fbfbfb;
}
.Trend_P4_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Trend_P4_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Trend_P4_S2_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 650px;
}
.Trend_P4_S3 {
  display: flex;
  gap: 42px;
}
.Trend_P4_S3_Box {
  width: 300px;
  height: 350px;
  border-radius: 8px;
  background: var(--white, #fff);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 30px;
  align-items: center;
  /* gap: 24px; */
}
.Trend_P4_S3_Box_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Trend_P4_S3_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 27.004px */
  width: 255px;
}
/* end */
/*Begin part4 Responsive */
@media (max-width: 475px) {
  .Trend_FourthPart {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #fbfbfb;
    width: 100%;
  }
  .Trend_P4_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .Trend_P4_S3 {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .Trend_P4_S1_H1 {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    width: 350px;
  }
  .Trend_P4_S2_P {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    text-align: center;
    width: 350px;
  }
}

/*End of responsive part four*/
/* style Fifth Part */
.Trend_FifthPart {
  display: flex;
  padding: 56px;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.Trend_P5_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 45vw;
}
.Trend_P5_S1_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 40vw;
}
.Trend_P5_S2_img {
  width: 35vw;
}
/*Part Five Responsive*/
@media (max-width: 475px) {
  .Trend_P5_S1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Trend_FifthPart {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 0px;
    align-items: center;
    justify-content: space-between;
  }
  .Trend_P5_S1_H1 {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 67.2px */
    text-transform: uppercase;
    width: 350px;
    text-align: center;
  }
  .Trend_P5_S1_P {
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 350px;
    text-align: center;
  }
  .Trend_P5_S2_img {
    width: 350px;
  }
}
/* style Sixth Part */
.Trend_SixthPart {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 57px;
  border-radius: 8px;
  padding: 0 20px;
}
.Trend_P6_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Trend_P6_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}
.Trend_P6_S2_Container {
  display: flex;
  padding: 20px;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
}
.Trend_P6_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* text-align: center; */
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(255, 202, 167, 0) 0%,
    #ffd8be7d 80%,
    rgba(255, 202, 167, 0) 100%
  );
  width: 350px;
  padding: 10px 10px 50px 20px;
  height: 130px;
}
.Trend_P6_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Trend_P6_S2_Box_P {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 350px;
  margin: 0;
}
/* end */
/*Section_Six_Responsive*/
@media (max-width: 475px) {
  .Trend_SixthPart {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
  }
  .Trend_P6_S1_H1 {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    width: 350px;
    text-align: center;
  }
  .Trend_P6_S2_Box {
    width: 350px;
  }
  .Trend_P6_S2_Box_H3 {
    padding: 0 10px;
  }
  .Trend_P6_S2_Box_P {
    width: 300px;
    padding: 0 10px;
  }
  .Trend_P6_S2_Container {
    flex-direction: column;
  }
}
/* Style Seventh Part */
.Trend_SeventhPart {
  display: flex;
  padding: 55px 30px;
  flex-direction: column;
  align-items: center;
  /* gap: 0px; */
}
.Trend_P7_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  width: 900px;
}
.Trend_P7_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 870px;
  margin-bottom: 56px;
}
/* end */
@media (max-width: 475px) {
  .Trend_SeventhPart {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .Trend_P7_S1_H1 {
    color: #000;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    width: 350px;
  }
  .Trend_P7_S1_P {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 350px;
    margin-bottom: 56px;
  }
  .Trend_P7_S3_Img {
    width: 350px;
  }
}
/* style Eight Part */
.Trend_EightPart {
  display: flex;
  padding: 56px 48px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(255, 202, 167, 0) -10%,
    #ffd8be43 50%,
    rgba(255, 202, 167, 0) 105%
  );
}
.Trend_P8_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Trend_P8_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 870px;
  margin-top: 0;
  margin-bottom: 56px;
}
.Trend_P8_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.Trend_P8_S2_Container {
  display: flex;
  gap: 32px;
}
.Trend_P8_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);
  width: 316px;
  padding: 10px 10px 50px 20px;
}
.Trend_P8_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Trend_P8_S2_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 290px;
  margin: 0;
}
/* start part_Eight Responsive */
@media (max-width: 475px) {
  .Trend_EightPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    border-radius: 8px;
    background: linear-gradient(
      0deg,
      rgba(255, 202, 167, 0) -10%,
      #ffd8be43 50%,
      rgba(255, 202, 167, 0) 105%
    );
  }
  .Trend_P8_S2_Box {
  }
  .Trend_P8_S1_H1 {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    width: 275px;
    text-align: center;
    padding-top: 10px;
  }
  .Trend_P8_S1_P {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 300px;
    margin-top: 0;
    margin-bottom: 56px;
  }
  .Trend_P8_S2_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
/* end */
/* Style Ninth Part */
.Trend_NinthPart {
  display: flex;
  padding: 56px 116px;
  flex-direction: column;
  align-items: center;
  gap: 72px;
  background: #fbfbfb;
}
.Trend_P9_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Trend_P9_S2 {
  display: flex;
  gap: 50px;
  align-items: center;
}
.Trend_P9_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 50px 30px;
  border-radius: 8px;
  background: var(--white, #fff);
  width: 320px;
  height: 220px;
}
.Trend_P9_S2_Box_H3 {
  color: var(--Red, #cc1d4a);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  width: 300px;
}
.Trend_P9_S2_Box_H3_B2 {
  color: var(--Red, #cc1d4a);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  width: 300px;
  margin-bottom: 60px;
}
.Trend_P9_S2_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 270px;
  margin: 0;
}
/* Start part nine Responsive */
@media (max-width: 475px) {
  .Trend_NinthPart {
    display: flex;
    padding: 20px 15px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #fbfbfb;
  }
  .Trend_P9_S1_H1 {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    width: 275px;
  }
  .Trend_P9_S2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
/* end */
/* style tenth part */
.Trend_TenthPart {
  display: flex;
  padding: 50px;
  justify-content: space-between;
  align-items: center;
}
.Trend_P10_Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}
.Trend_P10_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Trend_P10_S2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.Trend_P10_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Trend_P10_S2_Box_H3 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  margin: 0;
}
.Trend_P10_S2_Box_P {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 33.005px */
  width: 469px;
  margin-top: 0;
}
.Trend_P10_S3_Img {
  width: 45vw;
  margin-top: 70px;
}
/* end */
/* start part ten Ressponsive*/
@media (max-width: 475px) {
  .Trend_TenthPart {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .Trend_P10_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .Trend_P10_S1_H1 {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
  }
  .Trend_P10_S2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .Trend_P10_S2_Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
  }
  .Trend_P10_S2_Box_H3 {
    color: var(--Red, #cc1d4a);
    font-family: "Gotham", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 36.005px */
    margin: 0;
  }
  .Trend_P10_S2_Box_P {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 33.005px */
    width: 350px;
    align-items: center;
    text-align: center;
  }
  .Trend_P10_S3_Img {
    width: 350px;
  }
}
/* Style Eleventh Part */
.Trend_EleventhPart {
  display: flex;
  padding: 56px 80px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fbfbfb;
}
.Trend_P11_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  width: 900px;
}
.Trend_P11_S2_P {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 820px;
}
/* end */
/* start part eleven Responsive */
@media (max-width: 475px) {
  .Trend_EleventhPart {
    display: flex;
    flex-direction: column;
    padding: 20px 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fbfbfb;
  }
  .Trend_P11_S1_H1 {
    color: #000;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    width: 275px;
  }
  .Trend_P11_S2_P {
    color: #000;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 350px;
    text-align: center;
  }
}
/* style twelth part */
.Trend_TwelvethPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 10px 50px 70px 50px;
}
.Trend_P12_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Trend_P12_S2 {
  display: flex;
  gap: 50px;
  align-items: center;
}
.Trend_P12_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px 30px 30px;
  border-radius: 8px;
  background: var(--Red, #cc1d4a);
  width: 320px;
  height: 220px;
}
.Trend_P12_S2_Box_H3 {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  width: 300px;
}

.Trend_P12_S2_Box_P {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 270px;
  margin: 0;
}
/*start part 12 Responsive */
@media (max-width: 475px) {
  .Trend_P12_S1_H1 {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    width: 320px;
  }
  .Trend_P12_S2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .Trend_P12_S2_Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px 30px 30px;
    border-radius: 8px;
    background: var(--Red, #cc1d4a);
    width: 320px;
    height: 220px;
  }
}
/*Start part 13*/
.Trend_ThirteenthPart {
  display: flex;
  padding: 56px;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  background: #fbfbfb;
}
.Trend_P13_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.Trend_P13_S2_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */
  text-transform: uppercase;
  width: 45vw;
  /* margin: 0; */
}
.Trend_P13_S2_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 45vw;
}
.Trend_P13_S1 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 45vw;
}
.Trend_P13_S1_Img {
  width: 40vw;
}
/* end */
/* start part 13 responsive*/
@media (max-width: 475px) {
  .Trend_ThirteenthPart {
    display: flex;
    padding: 20px 15px;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    background: #fbfbfb;
  }
  .Trend_P13_S2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  .Trend_P13_S2_H1 {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%; /* 56px */
    text-transform: uppercase;
    width: 350px;
    padding-top: 10px;
    text-align: center;
    margin: 0;
  }
  .Trend_P13_S2_P {
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 350px;
    text-align: center;
  }
  .Trend_P13_S1_Img {
    width: 70vw;
    margin-left: -50px;
  }
}
/* style Fourteenth Part */
.Trend_FourteenthPart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 50px;
  background: linear-gradient(
    0deg,
    rgba(255, 202, 167, 0) -10%,
    #ffd8be43 50%,
    rgba(255, 202, 167, 0) 105%
  );
}
.Trend_P14_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 850px;
  margin: 0;
}
.Trend_P14_S2_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 800px;
}
@media (max-width: 475px) {
  .Trend_FourteenthPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
  }
  .Trend_P14_S1_H1 {
    color: #000;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 67.2px */
    text-transform: uppercase;
    width: 300px;
    margin: 0;
  }
  .Trend_P14_S2_P {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 320px;
  }
}
