.CaseStudies_Container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 83px;
}

.CaseStudies_FirstPart {
  background-image: url("../../assets/svg/banner_CaseStudy.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 42px;
  height: 250px;
  position: relative;
}
/* .CaseStudies_FirstPart::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.CaseStudies_FirstPart > * {
  position: relative;
  z-index: 2;
} */
.CaseStudies_P1_contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.CaseStudies_P1_first p {
  text-transform: uppercase;
  text-align: center;
  color: var(--white, #fff);
  font-family: "Galifex", Arial, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 54.008px */
}
.CaseStudies_P1_second {
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  color: var(--white, #fff);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 36.005px */
}
.CaseStudies_P1_third {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  max-width: 750px;
}

@media (max-width: 475px) {
  .CaseStudies_Container {
    gap: 0;
  }
  .CaseStudies_FirstPart {
    display: flex;
    justify-content: center;
    background-image: url("../../assets/svg/banner_CaseStudy.svg");
    height: 400px;
    padding: 12px;
  }
  .CaseStudies_P1_first p {
    font-size: 20px;
  }
  .CaseStudies_P1_second {
    font-size: 16px;
    width: 324px;
  }
  .CaseStudies_P1_third {
    font-size: 16px;
    max-width: 324px;
  }
  .CaseStudies_P1_contain {
    gap: 50px;
  }
}

.CaseStudiesMain_SecondPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 83px;
}
.CaseStudiesMain_P2_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
}
.CaseStudiesMain_P2_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.CaseStudiesMain_P2_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
  width: 1023px;
}
.CaseStudiesMain_P2_S2 {
  display: flex;
  padding: 0px 80px;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  align-self: stretch;
  padding-bottom: 80px;
}
.CaseStudiesX_P2_Box {
  display: flex;
  width: 350px;
  height: 500px;
  padding: 10px 24px 24px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f7f6f6;
  gap: 24px;
}
.CaseStudiesX_P2_Box_S1_H3 {
  color: #000;
  font-family: "Galifex", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}
.CaseStudiesX_P2_Box_S1_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  align-self: stretch;
  width: 340px;
}
.casestudiesXP2One {
  filter: grayscale(100%);
}

.AmerGroup {
  width: 13vw;
}
.Damietta {
  width: 13vw;
}

.CaseStudiesX_P2_Box_S3_A:hover .casestudiesXP2One {
  filter: grayscale(0%);
}
.CaseStudiesX_P2_Box_S3 {
  width: 100%;
}
.CaseStudiesX_P2_Box_S3_Button {
  display: flex;
  height: 62px;
  padding: 16px 9px 16px 0px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #1e0d12;
  cursor: pointer;
  width: 100%;
}
.CaseStudiesX_P2_Box_S3_A {
}
.CaseStudiesX_P2_Box_S3_Button_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: capitalize;
}
@media (max-width: 475px) {
  .CaseStudiesMain_SecondPart {
    padding: 20px;
    align-items: center;
    justify-content: center;
  }
  .CaseStudiesMain_P2_S1 {
    align-items: center;
    text-align: center;
  }
  .CaseStudiesMain_P2_S1_H1 {
    font-size: 28px;
  }
  .CaseStudiesMain_P2_S1_P {
    width: 324px;
  }
  .CaseStudiesMain_P2_S2 {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .CaseStudiesX_P2_Box {
    width: 300px;
  }
  .AmerGroup {
    width: 50vw;
  }
  .Damietta {
    width: 50vw;
  }
}
.SLine {
  font-size: 22px !important;
}
