.SapAribaContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;
  overflow: hidden;
}

/* style First Part */
.AribaFirstPart {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 300px;
  padding: 56px 80px 32px 80px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex-shrink: 0;
  position: relative;
}
.Sap_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}
.Ariba_P1_S1 {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: -30px;
}
.Ariba_P1_S1 a,
.Ariba_P1_S1 a:visited,
.Ariba_P1_S1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.Ariba_P1_S1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
}
.KeyboardArrowLeftIcon {
  color: #fff;
}
.Ariba_P1_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Ariba_P1_H1 {
  color: var(--white, #fff);
  font-family: "Galifex";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
}
.Ariba_P1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}
@media (max-width: 475px) {
  .AribaFirstPart {
    padding: 30px;
  }
  .Ariba_P1_H3 {
    text-align: center;
    font-size: 20px;
  }
}
/* end */
/* style Second Part */
.AribaSecondPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.Ariba_P2_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Ariba_P2_S1_H1 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */
  text-transform: uppercase;
  width: 610px;
}
.Ariba_P2_S1_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 34.505px */
  width: 610px;
}
.AribaP2S2_Mobile {
  display: none;
}
.AribaP2S2_Web {
  width: 500px;
}
/* end  */
@media (max-width: 475px) {
  .AribaSecondPart {
    flex-direction: column;
  }
  .Ariba_P2_S1 {
    margin: 0;
  }
  .Ariba_P2_S1_H1 {
    width: 350px;
    text-align: center;
    font-size: 24px;
  }
  .Ariba_P2_S1_P {
    width: 350px;
    text-align: center;
  }
  .AribaP2S2_Mobile {
    display: block;
    width: 350px;
  }
  .AribaP2S2_Web {
    display: none;
  }
}

/* style Third Part */
.AribaThirdPart {
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.Ariba_P3_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Ariba_P3_S2_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 620px;
}
.Ariba_P3_S3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 56px;
  justify-content: center;
}
.Ariba_P3_S3_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: var(--white, #fff);
  padding: 20px 20px;
  width: 300px;
  height: 250px;
}
.Ariba_P3_S3_Box_H3 {
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Ariba_P3_S3_Box_P {
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
@media (max-width: 475px) {
  .AribaThirdPart {
    padding: 20px;
    justify-content: center;
  }
  .Ariba_P3_S1_H1 {
    width: 350px;
    font-size: 24px;
    text-align: center;
  }
  .Ariba_P3_S2_P {
    width: 350px;
    text-align: center;
  }
}
/* end */
/* style Fourth Part */
.AribaFourthPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  /* gap: 150px; */
}
.Ariba_P4_S2 {
  display: flex;
  flex-direction: column;
}
.Ariba_P4_S2_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 650px;
}
.Ariba_P4_S2_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 650px;
}
.AribaP4S1_Mobile {
  display: none;
}
.AribaP4S1_Web {
  width: 500px;
}
/* end */
@media (max-width: 475px) {
  .AribaFourthPart {
    flex-direction: column;
    gap: 50px;
  }
  .AribaP4S1_Mobile {
    display: block;
    width: 300px;
  }
  .AribaP4S1_Web {
    display: none;
  }
  .Ariba_P4_S2 {
    align-items: center;
    justify-content: center;
    gap: 24px;
  }
  .Ariba_P4_S2_H1 {
    width: 350px;
    font-size: 24px;
    text-align: center;
  }
  .Ariba_P4_S2_P {
    width: 350px;
    text-align: center;
  }
}

/* style Fifth Part */
.AribaFifthPart {
  display: flex;
  padding: 30px 30px;
  justify-content: space-between;
  align-items: center;
  background: #fbfbfb;
}

.Ariba_P5_S1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}
.Ariba_P5_S1 a,
.Ariba_P5_S1 a:active,
.Ariba_P5_S1 a:visited {
  text-decoration: none;
}
.Ariba_P5_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 650px;
  margin: 0;
}
.Ariba_P5_S1_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 650px;
}
.Ariba_P5_S1_Button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  border: none;
  /* for text */
  color: var(--white, #fff);
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.AribaP5S2Mobile {
  display: none;
}
.AribaP5S2Web {
  width: 600px;
}
@media (max-width: 475px) {
  .AribaFifthPart {
    padding: 20px;
    flex-direction: column;
    justify-content: center;
  }
  .Ariba_P5_S1 {
    align-items: center;
  }
  .Ariba_P5_S1_H1 {
    width: 350px;
    font-size: 24px;
    text-align: center;
  }
  .AribaP5S2Web {
    display: none;
  }
  .AribaP5S2Mobile {
    display: block;
    width: 300px;
  }
  .Ariba_P5_S1_P {
    width: 350px;
    font-size: 18px;
  }
}
/* end */
/* style Seventh part */
.AribaSixthPart {
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    #ffd8be43 0%,
    rgba(255, 202, 167, 0) 100%
  );
  height: 500px;
  /* #FFFFFF, #FFE5D381, #FFD8BE43, #FFCAA700, #FFCAA700 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px;
  gap: 50px;
}
.Ariba_P6s_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Ariba_P6_S2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Ariba_P6_S2 a,
.Ariba_P6_S2 a:active,
.Ariba_P6_S2 a:visited {
  text-decoration: none;
}
.Ariba_P6_S2_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  padding: 10px 20px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: var(--white, #fff);
  /* for text */
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 27.004px */
}

.Ariba_P6_S2_button:hover {
  background: #d21d4c;
  color: #fff;
}
@media (max-width: 475px) {
  .AribaSixthPart {
    margin: 0;
    height: auto;
    justify-content: center;
    padding: 20px;
    gap: 50px;
  }
  .Ariba_P6s_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .Ariba_P6_S2_button {
    width: 350px;
  }
}
