.HomeSliderAnimation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 700px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.HomeSlider_A_Back,
.HomeSlider_A_Forword {
  height: 100%;
  position: absolute;
  top: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.HomeSlider_A_Back {
  left: 0px;
  width: 70px;
}

.HomeSlider_A_Forword {
  right: 0;
  width: 70px;
}

.Home_Slider_Animation_Fixed {
  display: flex;
  padding: 32px 80px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 0;
}

.Home_Slider_Animation_Fixed_H1 {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Home_Slider_Animation_First,
.Home_Slider_Animation_Second,
.Home_Slider_Animation_Third,
.Home_Slider_Animation_Fourth,
.Home_Slider_Animation_Fifth,
.Home_Slider_Animation_Sixth {
  display: flex;
  padding: 32px 48px 32px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 67px;
  height: 630px;
  width: 480px;
  position: relative;
}
.Home_Slider_Animation_Main {
  margin-top: 350px;
}
.Home_Slider_Animation_First_H1,
.Home_Slider_Animation_Second_H1,
.Home_Slider_Animation_Third_H1,
.Home_Slider_Animation_Fourth_H1,
.Home_Slider_Animation_Fifth_H1,
.Home_Slider_Animation_Sixth_H1 {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 155.023%; /* 37.205px */
}
/* .Animation_F1_H1 {
} */
.Home_Slider_Animation_First_Button,
.Home_Slider_Animation_Second_Button,
.Home_Slider_Animation_Third_Button,
.Home_Slider_Animation_Fourth_Button,
.Home_Slider_Animation_Fifth_Button,
.Home_Slider_Animation_Sixth_Button {
  position: absolute;
  bottom: 10px;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #f89a5b;
  background: transparent;
}
.explore-text {
  color: #f89a5b;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.Home_Slider_Animation_Second:hover,
.Home_Slider_Animation_First:hover,
.Home_Slider_Animation_Third:hover,
.Home_Slider_Animation_Fourth:hover,
.Home_Slider_Animation_Fifth:hover,
.Home_Slider_Animation_Sixth:hover {
  background: rgba(30, 13, 18, 0.3);
}

.Home_Slider_Animation_First:hover .Home_Slider_Animation_First_H1,
.Home_Slider_Animation_Second:hover .Home_Slider_Animation_Second_H1,
.Home_Slider_Animation_Third:hover .Home_Slider_Animation_Third_H1,
.Home_Slider_Animation_Fourth:hover .Home_Slider_Animation_Fourth_H1,
.Home_Slider_Animation_Fifth:hover .Home_Slider_Animation_Fifth_H1,
.Home_Slider_Animation_Sixth:hover .Home_Slider_Animation_Sixth_H1 {
  transform: translateY(-70px);
  transition: transform 0.3s ease;
}

.Home_Slider_Animation_First_P,
.Home_Slider_Animation_Second_P,
.Home_Slider_Animation_Third_P,
.Home_Slider_Animation_Fourth_P,
.Home_Slider_Animation_Fifth_P,
.Home_Slider_Animation_Sixth_P {
  transform: translateY(-20px);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.Home_Slider_Animation_First:hover .Home_Slider_Animation_First_P,
.Home_Slider_Animation_Second:hover .Home_Slider_Animation_Second_P,
.Home_Slider_Animation_Third:hover .Home_Slider_Animation_Third_P,
.Home_Slider_Animation_Fourth:hover .Home_Slider_Animation_Fourth_P,
.Home_Slider_Animation_Fifth:hover .Home_Slider_Animation_Fifth_P,
.Home_Slider_Animation_Sixth:hover .Home_Slider_Animation_Sixth_P {
  opacity: 1;
  color: #fff;
  font-family: "Inter" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%;
  margin-bottom: 100px;
}
.Home_P3_Button {
  display: none;
}
/* CSS */
@media (max-width: 475px) {
  .Home_Slider_Animation_First:hover .Home_Slider_Animation_First_P,
  .Home_Slider_Animation_Second:hover .Home_Slider_Animation_Second_P,
  .Home_Slider_Animation_Third:hover .Home_Slider_Animation_Third_P,
  .Home_Slider_Animation_Fourth:hover .Home_Slider_Animation_Fourth_P,
  .Home_Slider_Animation_Fifth:hover .Home_Slider_Animation_Fifth_P,
  .Home_Slider_Animation_Sixth:hover .Home_Slider_Animation_Sixth_P {
    font-size: 16px;
    width: 300px;
  }
  .Home_Slider_Animation_First_H1,
  .Home_Slider_Animation_Second_H1,
  .Home_Slider_Animation_Third_H1,
  .Home_Slider_Animation_Fourth_H1,
  .Home_Slider_Animation_Fifth_H1,
  .Home_Slider_Animation_Sixth_H1 {
    font-size: 20px;
    width: 324px;
  }

  .Home_Slider_Animation_First_P,
  .Home_Slider_Animation_Second_P,
  .Home_Slider_Animation_Third_P,
  .Home_Slider_Animation_Fourth_P,
  .Home_Slider_Animation_Fifth_P,
  .Home_Slider_Animation_Sixth_P {
    transform: translateY(-20px);
    opacity: 1;
    transition: opacity 1;
    color: #fff;
    width: 250px;
    font-size: 16px;
  }
  .Home_Slider_Animation_Main {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    gap: 50px;
  }
  .Home_Slider_Animation_Fixed {
    align-items: flex-start;
  }
  .Home_Slider_Animation_First,
  .Home_Slider_Animation_Second,
  .Home_Slider_Animation_Third,
  .Home_Slider_Animation_Fourth,
  .Home_Slider_Animation_Fifth,
  .Home_Slider_Animation_Sixth {
    align-items: center;
    gap: 32px;
    height: 630px;
    width: 300px;
    position: relative;
  }
  .Home_Slider_Animation_First:hover .Home_Slider_Animation_First_P,
  .Home_Slider_Animation_Second:hover .Home_Slider_Animation_Second_P,
  .Home_Slider_Animation_Third:hover .Home_Slider_Animation_Third_P,
  .Home_Slider_Animation_Fourth:hover .Home_Slider_Animation_Fourth_P,
  .Home_Slider_Animation_Fifth:hover .Home_Slider_Animation_Fifth_P,
  .Home_Slider_Animation_Sixth:hover .Home_Slider_Animation_Sixth_P {
    font-size: 16px;
    margin-bottom: 10px;
    width: 300px;
  }
  .Home_Slider_Animation_First_H1,
  .Home_Slider_Animation_Second_H1,
  .Home_Slider_Animation_Third_H1,
  .Home_Slider_Animation_Fourth_H1,
  .Home_Slider_Animation_Fifth_H1,
  .Home_Slider_Animation_Sixth_H1 {
    color: #fff;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 155.023%; /* 37.205px */
  }
}
