/* Main container using flex to position buttons and content */
.devOpsMoreInfoContainer {
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
}

/* Buttons container */
.DevOps_MoreInfo_container {
  display: flex;
  gap: 40px;
  border-bottom: 3px solid #d8d8d8;
}

/* Button styles */
.DevOps_MoreInfo_button {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  cursor: pointer;
  color: #1e0d12;
  position: relative;
}

/* Active button style */
.DevOps_MoreInfo_active {
  border-bottom: 3px solid #d41e4d;
}

/* Specific style for individual buttons */
.DevOps_MoreInfo_individual {
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
}

.content_MoreInfo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 950px;
}

.content_MoreInfo_container P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
@media (max-width: 475px) {
  .devOpsMoreInfoContainer {
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
  }
  .content_MoreInfo_container {
    width: 324px;
  }
  .content_MoreInfo_container P {
    font-size: 14px;
    width: 300px;
    text-align: center;
    padding: 20px;
  }
  .DevOps_MoreInfo_container {
    overflow: hidden;
    width: 370px;
    flex-wrap: wrap;
    display: flex;
    gap: 10px;
    border-bottom: none;
    align-items: center;
    justify-content: center;
  }
  .DevOps_MoreInfo_individual {
    font-size: 12px;
  }
  .DevOps_MoreInfo_button {
    display: flex;
    padding: 10px 8px;
    align-items: center;
    gap: 12px;
    align-self: center;
    cursor: pointer;
    color: #1e0d12;
    position: relative;
  }
}
