/*  begin the cover have cover image and content in front */
.tenderBigContainer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  overflow: hidden;
}
.tendercover {
  background-image: url("../../assets/images/TenderCover.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
}

.tendercover::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.tendercover > * {
  position: relative;
  z-index: 2;
}

.coverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tendercover h4 {
  color: var(--white, #fff);
  font-size: 24px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
  margin: 0;
}

.tendercover h1 {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 60.009px */
}

.tendercover p {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 750px;
}

/*  End of cover in Desktop  */

/* Cover Responsive */

/* For small devices (mobile phones) */
@media (max-width: 475px) {
  .tendercover {
    background-image: url("../../assets/images/mobile.png");
    background-size: cover;
    height: 380px;
    align-items: center;
  }

  .coverContent {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    text-align: center;
  }

  .tendercover h4 {
    color: var(--white, #fff);
    font-size: 20px;
    font-family: "Gotham", sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: 150.023%; /* 30.005px */
    text-transform: uppercase;
  }

  .tendercover h1 {
    color: var(--white, #fff);
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 60.009px */
    text-align: center;
    width: 300px;
  }
  .tendercover p {
    color: var(--white, #fff);
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 350px;
  }
}

/* section one ==> contain our and slides */

/*ouside*/

.sectionone {
  display: flex;
  gap: 10px;
  padding: 50px;
  justify-content: space-between;
  align-items: flex-start;
}

.oursite h1 {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  font-family: "Gotham", sans-serif;
  line-height: normal;
  text-transform: uppercase;
  width: 45vw;
  margin-top: 0;
}
.oursite p {
  color: #000;
  font-size: 20px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 40vw;
}
/* For small devices (mobile phones) */
@media (max-width: 475px) {
  .sectionone {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
    align-items: center;
    justify-content: center;
  }

  .oursite {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .oursiteContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .oursite h1 {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-family: "Gotham", sans-serif;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
  .oursite p {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    font-family: "Gotham", sans-serif;
    width: 350px;
    padding-top: 20px;
    text-align: center;
  }
}

/*section one*/

/* For small devices (mobile phones)  Slide in Device  */

/* End of Slide Responsive */

/*section two*/

.sectiontwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 50px 35px;
  background-color: #f5f5f5;
}

.sectiontwo h1 {
  font-family: "Gotham", sans-serif;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.sectiontwoAcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For small devices (mobile phones)  Slide in Device  */
@media (max-width: 475px) {
  .sectiontwo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 30px 15px;

    background-color: #f5f5f5;
  }

  .sectiontwo h1 {
    width: 350px;
    color: #000;
    font-family: "Gotham", sans-serif;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }
}

/* section 3*/

.sectionthree {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  padding: 100px 0;
  background: linear-gradient(
    180deg,
    #ffd8be43 0%,
    rgba(255, 202, 167, 0) 100%
  );
}

.sectionthree_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
}

.sectionthree h1 {
  width: 900px;
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}

.sectionthree p {
  width: 600px;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-family: "Gotham", sans-serif;

  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 36.005px */
}

.sectionthree button {
  padding: 16px 24px;
  background: #d41e4d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

/* For small devices (mobile phones)  Slide in Device  */
@media (max-width: 475px) {
  .sectionthree {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 30px;
    padding-top: 30px;
    background: linear-gradient(
      180deg,
      #ffd8be43 0%,
      rgba(255, 202, 167, 0) 100%
    );
  }

  .sectionthree h1 {
    width: 350px;
    color: #000;
    text-align: center;
    font-size: 25px;
    font-family: "Gotham", sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }

  .sectionthree p {
    width: 350px;
    color: #000;
    text-align: center;
    font-size: 22px;
    font-family: "Gotham", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 36.005px */
  }

  .sectionthree button {
    background: #d41e4d;
    display: inline-flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    font-family: "Gotham", sans-serif;
    line-height: normal;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }
}

/* end section three responsive */
