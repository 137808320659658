.Rise_accordion_First {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 850px;
  min-width: 564px;
}

.Rise_accordion_First_item {
  /* margin-bottom: 10px; */
}

.Rise_accordion_First_header {
  padding: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-radius: 4px;
  background: var(--white, #fff);
}
.Rise_accordion_First_title {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}

.Rise_accordion_First_content {
  padding: 0 25px 20px 25px;
  background-color: #fff;
}
.Rise_Acc_First_Text {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.Rise_Acc_First_Text_H3 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 24.004px */
}
.Rise_Acc_First_Text_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  margin: 0;
}
@media (max-width: 475px) {
  .Rise_accordion_First {
    width: 350px;
    min-width: 350px;
  }
  .Rise_accordion_First_header {
    padding: 25px;
  }
  .Rise_accordion_First_title {
    font-size: 16px;
  }
}
