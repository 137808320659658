/* style of submenu more */
/* style for all container */
.submenu_moreMain {
  display: flex;
  width: 100%;
  max-width: 100%;
  background: #17060b;
  box-shadow: 0px 8px 20px 0px rgba(30, 13, 18, 0.25);
  align-items: flex-start;
  position: absolute;
  top: 50;
  left: 0;
  z-index: 100000000000;
  height: 20vh;
}
.submenu_contain_more {
  width: 100%;
  padding: 48px;
}
/* end style of all container */

/* style of pages */

.submenu_more_contain {
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.submenu_more_contain a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

.submenu_more_contain a:hover {
  border-bottom: 1px solid #cc1d4a;
  background: #1e0d12;
}

.submenu_more_link {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

/*  */
