.HomeP4_S2_E {
  display: flex;
  align-items: center;
}
.HomeP4_S2_Elmement_P {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  text-transform: uppercase;
}
.Home_P4_accordion_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.13vw 2.272vw;
  height: 30px;
}
.Home_P4_accordion_item .Home_P4_accordion_content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  padding: 0 2.272vw;
}

.Home_P4_accordion_item:hover .Home_P4_accordion_content {
  max-height: 500px;
  padding: 1.13vw 2.272vw;
}

.Home_P4_accordion_item_container {
  border-bottom: 1px solid #e5d6da;
}
.Home_P4_accordion_content_Main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5vw;
}
.Home_P4_accordion_item_container_H3 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 24.004px */
}
.Home_P4_accordion_content_Img {
  width: 15vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Home_P4_accordion_content_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 27.5vw;
}

/* .Home_P4_accordion_content_Main_First {
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
} */
@media (max-width: 475px) {
  .HomeP4_S2_Elmement_P {
    font-size: 14px;
  }
  .Home_P4_accordion_header {
    padding: 20px;
  }
  .Home_P4_accordion_item_container_H3 {
    font-size: 16px;
  }
  .Home_P4_accordion_content_P {
    font-size: 16px;
    text-align: center;
    width: 90vw;
  }
  .Home_P4_accordion_content_Main {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .Home_P4_accordion_item:hover .Home_P4_accordion_content {
    padding: 16px 24px;
  }
  .Home_P4_accordion_content_Img {
    width: 40vw;
  }
  .HomeP4Aws {
    width: 40vw;
  }
  .HomeP4AwsW {
    width: 40vw;
  }
}
