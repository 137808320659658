.OilsGas_Container {
  display: flex;
  flex-direction: column;
  gap: 56px;
  background: var(--white, #fff);
  overflow: hidden;
}
.OlisGas_FirstPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 50px;
  background: #fbfbfb;
}
.OlisGas_P1_S1 {
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
}
.OlisGas_P1_S1_H1 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */
  text-transform: uppercase;
}
.OlisGas_P1_S1_P {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 34.505px */
  width: 50vw;
}

/* end */
/* style Second Part */
.OlisGas_SecondPart {
  display: flex;
  padding: 48px 103px 91.845px 104px;
  flex-direction: column;
  align-items: center;
  gap: 72px;
}
.OlisGas_P2_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.OlisGas_P2_S2 {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.OlisGas_P2_S2_L {
  display: flex;
  align-items: center;
  gap: 50px;
}
.OilsGasP1S2Mobile {
  display: none;
}
.OilsGasP2S2PetroJet {
  width: 200px;
}
.OilsGasP2S2Egas {
  width: 250px;
  height: 170px;
}
@media (max-width: 475px) {
  .OilsGas_Container {
    gap: 32px;
  }
  .OlisGas_FirstPart {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  .OlisGas_P1_S1 {
    gap: 32px;
  }
  .OlisGas_P1_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .OlisGas_P1_S1_P {
    font-size: 16px;
    width: 350px;
    text-align: center;
    margin-bottom: 0;
  }
  .OilsGasP1S2Web {
    display: none;
  }
  .OilsGasP1S2Mobile {
    display: block;
    width: 350px;
  }
  .OlisGas_P2_S2_L {
    flex-direction: column;
  }
  .OlisGas_P2_S1_H1 {
    font-size: 20px;
    text-align: center;
  }
}
