.SapSuccessFactorsContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;
  overflow: hidden;
}

/* style First Part */
.SuccessFactorsFirstPart {
  /* background-image: url(../../assets/svg/SuccessFactors_Banner.svg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 300px;
  padding: 56px 80px 32px 80px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex-shrink: 0;
  position: relative;
}
.Sap_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.Success_P1_S1 {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  margin-top: -30px;
}
.Success_P1_S1 a,
.Success_P1_S1 a:visited,
.Success_P1_S1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.Success_P1_S1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
}
.KeyboardArrowLeftIcon {
  color: #fff;
}
.Success_P1_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Success_P1_H1 {
  color: var(--white, #fff);
  font-family: "Galifex";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
}
.Success_P1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

@media (max-width: 475px) {
  .Success_P1_H1 {
    text-align: center;
  }
  .Success_P1_S1 {
    width: 300px;
  }
  .Success_P1_H3 {
    text-align: center;
  }
}
/* end style First Part */

/* Style Second Part */
.SuccessFactorsSecondPart {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 68px;
  flex-shrink: 0;
  align-self: stretch;
  padding: 87px 0px 86px 0px;
}
.Success_P2_S1 {
  display: flex;
  padding: 64px 0px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 24px; if need it  */
  align-self: stretch;
}
.Success_P2_S1_H1 {
  width: 545px;
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 33.6px */
  text-transform: uppercase;
}
.Success_P2_S1_P {
  width: 650px;
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
.SuccessFactorsP2S1Mobile {
  display: none;
}
/* end  */
@media (max-width: 475px) {
  .SuccessFactorsSecondPart {
    flex-direction: column;
    padding: 0;
  }
  .Success_P2_S1 {
    padding: 0;
    align-items: center;
  }
  .Success_P2_S1_H1 {
    font-size: 20px;
    width: 350px;
    text-align: center;
  }
  .Success_P2_S1_P {
    font-size: 18px;
    width: 350px;
    text-align: center;
  }

  .SuccessFactorsP2S1Web {
    display: none;
  }
  .SuccessFactorsP2S1Mobile {
    display: block;
    width: 350px;
  }
}

/* style Third Part */
.SuccessFactorsThirdPart {
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
  align-items: center;
  background: #fbfbfb;
  padding: 87px 0 86px 0;
}
.Success_P3_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Success_P3_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 700px;
  margin-bottom: 100px;
}
.Success_P3_S2 {
  display: flex;
  gap: 48px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* padding: 0 200px 0 200px; */
}
.Success_P3_S2_Box {
  display: flex;
  flex-direction: column;
}
.Success_P3_S2_Box_H3 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
}
.Success_P3_S2_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 520px;
}
@media (max-width: 475px) {
  .SuccessFactorsThirdPart {
    padding: 30px 20px;
    align-items: center;
    justify-content: center;
  }
  .Success_P3_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .Success_P3_S1_P {
    width: 350px;
    font-size: 18px;
  }
  .Success_P3_S2_Box_H3 {
    text-align: center;
  }
  .Success_P3_S2_Box_P {
    width: 350px;
    text-align: center;
  }
}
/* end */
/* style Fourth Part */
.SuccessFactorsFourthPart {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.Success_P4_S1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 50px 50px 50px;
}
.Success_P4_S1_Text {
  display: flex;
  flex-direction: column;
}
.Success_P4_S1_Text_H1 {
  width: 788px;
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Success_P4_S1_Text_P {
  width: 644px;
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
}
.Success_P4_S2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 50px;
}
.Success_P4_S2_P {
  width: 1084px;
  color: #4d4346;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  letter-spacing: -0.24px;
}
.SuccessFactorsP4S1Mobile {
  display: none;
}
.SuccessFactorsP4S1Web {
  width: 500px;
}
/* end */
@media (max-width: 475px) {
  .Success_P4_S1 {
    flex-direction: column;
    padding: 0;
  }
  .Success_P4_S1_Text {
    align-items: center;
    gap: 32px;
  }
  .Success_P4_S1_Text_H1 {
    font-size: 22px;
    width: 350px;
    text-align: center;
  }
  .Success_P4_S1_Text_P {
    font-size: 18px;
    width: 350px;
    text-align: center;
  }
  .SuccessFactorsP4S1Mobile {
    display: block;
    width: 300px;
  }
  .SuccessFactorsP4S1Web {
    display: none;
  }
  .Success_P4_S2_P {
    font-size: 20px;
    width: 350px;
  }
}
/* style Fifth Part */
.SuccessFactorsFifthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.Success_P5_S1 {
  display: flex;
  align-items: center;
  text-align: center;
}
.Success_P5_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Success_P5_S2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-bottom: 100px;
}
.Success_P5_S2_Box {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fbfbfb;
  padding: 20px 60px 20px 20px;
  /* padding: 20px;
  width: 624px; */
  flex-shrink: 0;
}
.Success_P5_S2_Box_H3 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
}
.Success_P5_S2_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 480px;
}
@media (max-width: 475px) {
  .Success_P5_S1_H1 {
    font-size: 24px;
  }
  .Success_P5_S2 {
    margin: 0;
  }
  .Success_P5_S2_Box {
    padding: 20px;
  }
  .Success_P5_S2_Box_H3 {
    text-align: center;
  }
  .Success_P5_S2_Box_P {
    width: 350px;
    text-align: center;
  }
}
/* end */
/* style Sixth Part */
.SuccessFactorsSixthPart {
  display: flex;
  padding: 60px 31px 82px 32px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  background: #fbfbfb;
}
.Success_P6_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Success_P6_S2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
}
.Success_P6_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white, #fff);
  padding: 20px;
}
.Success_P6_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Success_P6_S2_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 402px;
}
.Success_P6_S2_Box:hover {
  background: #d21d4c;
}

.Success_P6_S2_Box:hover .Success_P6_S2_Box_H3,
.Success_P6_S2_Box:hover .Success_P6_S2_Box_P {
  color: #fff;
}
@media (max-width: 475px) {
  .SuccessFactorsSixthPart {
    padding: 20px;
    gap: 20px;
  }
  .Success_P6_S1_H1 {
    font-size: 24px;
  }
  .Success_P6_S2_Box_P {
    width: 350px;
  }
}
/* end */
/* style Seventh part */
.SuccessFactorsSeventhPart {
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    #ffd8be43 0%,
    rgba(255, 202, 167, 0) 100%
  );
  margin-top: -56px;
  height: 500px;
  /* #FFFFFF, #FFE5D381, #FFD8BE43, #FFCAA700, #FFCAA700 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 70px;
  gap: 50px;
}
.Success_P7_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Success_P7_S2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Success_P7_S2 a,
.Success_P7_S2 a:active,
.Success_P7_S2 a:visited {
  text-decoration: none;
}
.Success_P7_S2_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 700px;
  padding: 10px 20px;
  flex-shrink: 0;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: var(--white, #fff);
  /* for text */
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 27.004px */
}

.Success_P7_S2_button:hover {
  background: #d21d4c;
  color: #fff;
}
@media (max-width: 475px) {
  .SuccessFactorsSeventhPart {
    margin: 0;
    height: auto;
    justify-content: center;
    padding: 20px;
    gap: 50px;
  }
  .Success_P7_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .Success_P7_S2_button {
    width: 350px;
  }
}
