/* Main container using flex to position buttons and content */
.devOpsButtonsContainer {
  display: flex;
  gap: 50px;
  border: 1px solid #d8d8d8;
}

/* Buttons container */
.DevOps_buttonsOne_container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mySvgClass {
  width: 50px;
}
/* Button styles */
.DevOps_buttonsOne_button {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-bottom: 1px solid #1e0d12;
  cursor: pointer;
  background: #fafafa;
  color: #1e0d12;
}
.DevOps_buttonsOne_button:last-child {
  border: none;
}
/* Active button style */
.DevOps_buttonsOne_active {
  background: #d41e4d;
  color: white;
}

/* Content styles */
.DevOps_buttonsOne_content {
  flex: 1.5;
  padding: 20px 32px 24px 20px;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

/* Specific style for individual buttons */
.DevOps_buttonOne_individual {
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%;
}

.content_container {
  display: flex;
  padding: 24px 32px 24px 0px;
  flex-direction: column;
}

.content_container svg {
  width: 58px;
  height: 58px;
}

.content_container h2 {
  color: #d41e4d;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
}

.content_container P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}
.DevOps_buttonsOne_content_Mobile {
  display: none;
}
@media (max-width: 475px) {
  .devOpsButtonsContainer {
    flex-direction: column;
  }
  .DevOps_buttonOne_individual {
    font-size: 12px;
  }
  .DevOps_buttonsOne_content {
    display: none;
  }
  .DevOps_buttonsOne_content_Mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .DevOps_buttonsOne_content_Mobile svg {
    width: 36px;
    height: 36px;
    align-items: center;
  }
  .DevOps_buttonsOne_content_Mobile h2 {
    font-size: 18px;
  }
  .DevOps_buttonsOne_content_Mobile p {
    font-size: 14px;
    text-align: center;
  }
}
