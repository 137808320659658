.slide {
  display: grid;
  grid-template-columns: repeat(1, 0.1fr); /* 2 columns with equal width */
  grid-template-rows: repeat(1, 0fr); /* 2 rows with equal height */
  gap: 10px; /* Optional gap between grid items */
  margin-top: 20px;
}

.boxinfo {
  border-radius: 16px;
  background: #fbfbfb;
  width: 320px;
  height: 150px;
  margin: 5px;
  /* margin-left: 5%; */
  padding: 5px;
}

.boxinfo h1 {
  text-align: center;
  color: #000;
  font-size: 18px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 8px;
}

.boxinfo p {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  padding: 5px;
}

/*controls have 2 arrow button */

/* control indicate to the active and nonactive svg + the right and left arrow */
.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
}

.arrowslide {
  display: flex;
  align-items: center;
  gap: 35px;
  justify-content: space-between;
  margin: 10px;
}

.activation {
  display: flex;
  gap: 3px;
  margin-top: 5px;
}
