@import url("../../assets/fonts/Galifix/stylesheet.css");

/* style Sap-PartnerShip page */
/* Start style sap First Part */
.Sap_container {
  display: flex;
  flex-direction: column;
  gap: 100px;
  overflow: hidden;
}
.Sap_FirstPart {
  /* background-image: url(../../assets/svg/sap_banner.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  padding: 0 35px;
}
.Partners_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  min-width: 100%;
  /* min-height: 100%; */
  width: 100%;
  height: 80vh;
  z-index: -1;
}

.Sap_P1_P1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -5vh;
}
.Sap_P1_P1_H1 {
  color: #fff;
  font-family: "Galifex", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150.023%;
  margin: 0;
}
.Sap_FirstPart_H1 {
  color: #fff;
  font-family: "Galifex", Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-top: 0;
}
.Sap_FirstPart_P {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 800px;
  margin: 0;
}
/* End style sap First Part */

/* Start style Sap Second Part */
.Sap_SecondPart {
  display: flex;
  padding: 40px 80px 56px 80px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: #f7f6f6;
  margin-top: -85px;
}
.Sap_P1_S1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.Sap_P1_S1_Main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.Sap_P1_S1_Main_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
  width: 200px;
}
.Sap_P1_S2 {
  color: #727272;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 867px;
}
/* End style Sap Second Part */

/* Start style Sap Third Part */
.Sap_ThirdPart {
  display: flex;
  padding: 0px 40px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}
.Sap_P3_S1 {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
}
.SapImgP3 {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-top: 10px;
}
.Sap_P3_S1_H1 {
  font-family: "Gotham", sans-serif;
  color: #1e0d12;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Sap_P3_S1_P {
  font-family: "Gotham", sans-serif;
  color: #1e0d12;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 45vw;
  max-width: auto;
}
/* End style Part 3 */

.SectionSpace {
  display: flex;
  justify-content: center;
  align-items: center;
  stroke-width: 1px;
  stroke: #d8d8d8;
}

/* Start style Sap Part Four */

.Sap_PartFour {
  display: flex;
  padding: 0px 80px;
  flex-direction: column;
  align-items: center;
  gap: 44px;
  align-self: stretch;
}
.Sap_PartFour_h1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.Sap_P4_S1_container {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
  perspective: 1500px;
}
.Sap_P4_S1_Main {
  width: 550px;
  height: 180px;
  flex-shrink: 0;
  background: #f7f6f6;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.Sap_P4_S1_Tag {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  color: #d21d4c;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
}
.Sap_P4_S1_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  margin-left: 35px;
  width: 464px;
}
/* style for hovering  */
.Sap_P4_S1_Main .hover-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  background: linear-gradient(96deg, #d2481d -36.34%, #ce375e 88.64%);
  transform: rotateX(-180deg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.hover-content-Main {
  display: flex;
  padding: 0px 0px 0px 32px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

.Sap_P4_S1_Main:hover {
  transform: rotateX(-180deg);
}
.Sap_P4_S1_Main .Sap_P4_S1_Tag,
.Sap_P4_S1_Main .Sap_P4_S1_P {
  backface-visibility: hidden;
}

.Sap_P4_S1_Main:hover .Sap_P4_S1_Tag,
.Sap_P4_S1_Main:hover .Sap_P4_S1_P {
  transform: translateY(100%);
}

.hover-content p {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

.hover-content-button,
.hover-content-button:visited,
.hover-content-button:active {
  display: flex;
  width: 130px;
  padding: 8px 4px;
  align-items: center;
  text-decoration: none;
}
.hover-content-button button {
  display: flex;
  padding: 8px 4px;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}
.SapArrowRightButton {
  transition: transform 0.3s ease;
}

.hover-content-button:hover .SapArrowRightButton {
  transform: scaleX(1.5);
}
/* End style Sap Part Four */

/* Start style Sap Part Four */
/* Parent container for the accordion items */
.Sap_PartFive {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
}
.Sap_P5_S1_Container {
  display: flex;
  padding: 0px 80px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}
.Sap_P5_S1_Main {
  display: flex;
  width: 500px;
  align-items: center;
  gap: 16px;
  border: none;
  background: transparent;
  border-bottom: 2px solid #d21d4c;
}

.acc_con {
  display: flex;
  gap: 100px;
}

/* style part 6 */
.Sap_PartSixContainer {
  display: flex;
  padding: 0px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.SapImgP3Img {
  width: 600px;
}
.SapMain_Blue {
  width: 120px;
}
.SapStarMobile {
  display: none;
}
@media (max-width: 475px) {
  .Sap_FirstPart {
    background-image: url(../../assets/images/sap_bannerMobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }
  .Partners_VideoBackground {
    display: none;
  }
  .Sap_P1_P1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10vh;
  }
  .Sap_P1_P1_H1 {
    text-align: center;
    color: #fff;
    font-family: "Galifex", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 150.023%;
    padding-top: 15px;
  }
  .SapMain_Plus {
    width: 20px;
  }

  .Sap_FirstPart_H1 {
    text-align: center;
    padding: 10px;
    font-size: 20px;
  }
  .Sap_FirstPart_P {
    margin: 0;
    width: 324px;
    font-size: 18px;
    display: none;
  }
  .Sap_SecondPart {
    padding-left: 0;
    padding-right: 0;
    margin-top: -56px;
  }
  .Sap_P1_S1 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 64px;
    padding: 10px;
  }
  .SpaceHidden {
    display: none;
  }
  .Sap_P1_S2 {
    width: 324px;
  }
  .Sap_ThirdPart {
    flex-direction: column;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
  }
  .Sap_P3_S1 {
    align-items: center;
  }
  .Sap_P3_S1_H1 {
    font-size: 20px;
  }
  .Sap_P3_S1_P {
    width: 350px;
    font-size: 18px;
    text-align: center;
  }
  .SapImgP3 {
    height: fit-content;
    width: 100%;
    margin: 0;
    padding-right: 20px;
  }
  .SapImgP3Img {
    width: 350px;
  }
  .Sap_container {
    gap: 50px;
  }
  .Sap_PartFour_h1 {
    font-size: 20px;
  }
  .Sap_P4_S1_Main {
    width: 300px;
    /* height: max-content; */
  }
  .Sap_P4_S1_Tag {
    font-size: 16px;
  }
  .Sap_P4_S1_P {
    width: 250px;
  }
  .hover-content-Main {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 10px;
    gap: auto;
    margin: 0;
  }
  .hover-content p {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  .Sap_PartFive {
    gap: 12px;
  }
  .SapStarMobile {
    display: block;
    width: 50px;
    display: none;
  }
}
