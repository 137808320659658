.submenuMobile_Solutions_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding: 10px;
  width: 95%;
}
.submenuMobile_Solutions_link {
  display: flex;
  align-items: center;
  align-self: stretch;
  padding-left: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;

  /* for text */
  color: var(--white, #fff);
  text-align: center;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 21.003px */
}
