.AzureContent_Container {
  display: flex;
  flex-direction: column;
  gap: 56px;
  background: #fbfbfb;
  overflow: hidden;
}
@media (max-width: 475px) {
  .AzureContent_Container {
    gap: 25px;
  }
}
/* Azure FirstPart */
.Azure_FirstPart {
  /* background-image: url(../../assets/svg/sap_banner.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  padding: 0 35px;
}
.Partners_Azure_VideoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  min-width: 100%;
  width: 100%;
  height: 80vh;
  z-index: 1;
}
.Azure_P1_S1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -12vh;
  z-index: 100;
}
.Azure_P1_S1_H1 {
  color: #fff;
  font-family: "Galifex", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150.023%;
  z-index: 100;
}
.Azure_FirstPart_H1 {
  color: #fff;
  font-family: "Galifex", Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-top: 0;
  z-index: 100;
}
.Azure_FirstPart_P {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 930px;
  margin-top: -10px;
  z-index: 100;
}
.AzureLogo {
  width: 100px;
}
@media (max-width: 475px) {
  /* About Body of page */
  .Azure_FirstPart {
    background-image: url(../../assets/images/sap_bannerMobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }
  .Azure_P1_S1_H1 {
    text-align: center;
    color: #fff;
    font-family: "Galifex", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 150.023%;
    padding-top: 15px;
  }
  .AzureStar {
    display: none;
  }
  .Azure_P1_S1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    margin-top: 5vh;
  }
  .Azure_FirstPart_H1 {
    text-align: center;
    color: #fff;
    font-family: "Galifex", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 150.023%;
    padding: 15px;
  }

  .Azure_FirstPart_P {
    display: none;
  }
  .AzureLogo {
    width: 70px;
  }
  .Partners_Azure_VideoBackground {
    display: none;
  }
}

/* Azure Second Part */
.Azure_SecondPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f8f8;
  padding-bottom: 100px;
}
.Azure_P2_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  width: 737px;
}
.Azure_P2_S3 {
  display: flex;
  gap: 30px;
}
.Azure_P2_S3_P {
  color: #515151;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  unicode-bidi: bidi-override;
  width: 830px;
}
.AzureP2S2Img {
  width: 800px;
  border-radius: 10px;
}
/* start secondpart Responsive*/
@media (max-width: 475px) {
  .Azure_SecondPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f8f8;
    padding-bottom: 20px;
  }
  .Azure_P2_S1_H1 {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    width: 350px;
  }
  .Azure_P2_S3 {
    display: flex;
    gap: 15px;
  }
  .Azure_P2_S3_P {
    color: #515151;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    unicode-bidi: bidi-override;
    width: 320px;
    text-align: center;
  }
  .Azure_p2_s2 {
    display: none;
  }
  .AzureP2S2Img {
    width: 350px;
  }
  .AzureP2S3Img {
    height: 550px;
  }
}
/* Style Third Part */
.Azure_ThirdPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 100px 50px;
}
.AzureP3S2Img {
  width: 600px;
  flex-shrink: 0;
  border-radius: 10px;
}
.Azure_P3_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 542px;
}
.Azure_P3_S1_P {
  color: #171717;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  width: 480px;
}
/* start third part Responsive */
@media (max-width: 475px) {
  .Azure_ThirdPart {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 20px 0;
    gap: 15px;
  }
  .AzureP3S2Img {
    width: 350px;
    height: 280px;
    flex-shrink: 0;
  }
  .Azure_P3_S1_H1 {
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    width: 300px;
    text-align: center;
  }
  .Azure_P3_S1_P {
    color: #171717;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    width: 320px;
    text-align: center;
  }
}
/* Style Part Four */
.Azure_FourthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #d41e4d;
  padding: 100px 0;
}
.Azure_P4_S1_H1 {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Azure_P4_S2_P {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  width: 900px;
  margin-bottom: 50px;
}
.Azure_P4_S3 {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: center;
}
.Azure_P4_S3_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
}
.Azure_P4_S3_Box_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 25.504px */
  margin-bottom: 0;
}
.Azure_P4_S3_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 220px;
}
/*  start part 4 Responsive */
@media (max-width: 475px) {
  .Azure_P4_S1_H1 {
    color: #fff;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
  .Azure_P4_S2_P {
    color: #fff;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    width: 320px;
    margin-bottom: 20px;
  }
}
/* Style Part 5 */
.Azure_FifthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 50px;
  gap: 70px;
}
.Azure_P5_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Azure_P5_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 47px; /* 146.875% */
  text-transform: uppercase;
  width: 927px;
}
.Azure_P5_S1_P {
  color: #171717;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  width: 780px;
}
/*start part 5 Responsive*/
@media (max-width: 475px) {
  .Azure_FifthPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 20px;
    gap: 10px;
  }
  .Azure_P5_S1_H1 {
    color: #000;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 47px; /* 146.875% */
    text-transform: uppercase;
    width: 320px;
  }
  .Azure_P5_S1_P {
    color: #171717;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    width: 350px;
  }
  .Azure_P5_S2 {
    width: 350px;
  }
  .Azure_Info_containerFirst {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-items: center;
    justify-content: center;
  }
}
/*Start Style Part Six */
.Azure_SixthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  background-color: #fff;
  padding: 100px;
}
.Azure_P6_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Azure_P6_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Azure_P6_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 720px;
}
.Azure_P6_S2 {
  display: flex;
  align-items: flex-start;
  gap: 44px;
}
/*start part 6 responsive*/
@media (max-width: 475px) {
  .Azure_SixthPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    padding: 20px;
  }
  .Azure_P6_S1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Azure_P6_S1_H1 {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    text-align: center;
  }
  .Azure_P6_S1_P {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 320px;
  }
  .Azure_P6_S2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
  }
  .Azure_P6_S2_Box_H3 {
    width: 250px;
  }
}
/* Style Part Seven */
.Azure_SevenPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  background-color: #fff;
  padding: 50px;
}
.Azure_P7_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  width: 827px;
}
.Azure_P7_S2 {
  display: flex;
  gap: 100px;
}
.Azure_P7_S2_E {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Azure_P7_S2_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}
.Azure_P7_S2_P {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 300px;
  margin: 0;
}
.AzureP7S2Img1,
.AzureP7S2Img2 {
  border-radius: 5px;
  width: 350px;
  height: 275px;
}
/*start part 7 responsive*/
@media (max-width: 475px) {
  .Azure_SevenPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #fff;
    padding: 20px;
  }
  .Azure_P7_S1_H1 {
    color: #000;
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    width: 300px;
  }
  .Azure_P7_S2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .Azure_P7_S2_E {
    align-items: center;
  }
  .Azure_P7_S2_H3 {
    text-align: center;
  }
  .Azure_P7_S2_P {
    text-align: center;
  }
}
.Azure_EightPart {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 50px;
  border-radius: 8px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 202, 167, 0) 100%);
}
.Azure_P8_S1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}
.Azure_P8_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  margin: 0;
}
.Azure_P8_S1_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 650px;
  margin: 0;
}
.Azure_P8_S1_Button {
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  border: none;
  /* for button text */
  color: #fff;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.AzureP8S2Img {
  width: 500px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 10px;
}
/* responsive part 8*/
@media (max-width: 475px) {
  .Azure_EightPart {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    background: linear-gradient(180deg, #fff 0%, rgba(255, 202, 167, 0) 100%);
  }
  .Azure_P8_S1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .Azure_P8_S1_H1 {
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 67.2px */
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    width: 300px;
  }
  .Azure_P8_S1_P {
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 320px;
    margin: 0;
    text-align: center;
  }
  .Azure_P8_S1_Button {
    display: inline-flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #d41e4d;
    border: none;
    /* for button text */
    color: #fff;
    font-family: "Inter" sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
  .AzureP8S2Img {
    width: 100%;
    border-radius: 10px;
  }
}
