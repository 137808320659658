.container_ButtonWithInput {
}

.container_ButtonWithInput button {
  padding: 10px 15px;
  cursor: pointer;
  width: 670px;
  display: flex;
  align-items: center;
  border: 1px solid #9d9d9d;
  background: #f7f6f6;
  font-family: "Gotham", sans-serif;
  color: #1e0d12;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  box-sizing: border-box;
}
.button_input_1 {
  display: flex;
  justify-content: space-between;
}
.button_input_1.no-border {
  border-bottom: none;
}
.container_ButtonWithInput textarea {
  width: 670px;
  height: 200px;
  padding: 10px;
  border: 1px solid #9d9d9d;
  border-top: none;
  background: #f7f6f6;
  box-sizing: border-box;
  outline: none; /* Remove the outline when focused */
  border: 1px solid #9d9d9d;
  border-top: none;
  /* Remove the border when focused */
}
@media (max-width: 475px) {
  .container_ButtonWithInput button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
  }
  .container_ButtonWithInput textarea {
    width: 100%;
  }
}
