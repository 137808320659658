/* section one cover contain cover and text in front image  */
.RealStateBigContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
/* image and atttributes */
.SectionOneCover {
  display: flex;
  justify-content: center;
  /* margin-bottom: 50px; */
  background-image: url("../../assets/images/TenderCover.png");
  background-size: cover;
  background-attachment: unset;
  height: 480px;
}

/* the content in front image    */
.coverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
}

.coverContent_H4_Text {
  color: var(--white, #fff);
  font-size: 20px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
  text-align: center;
}

.coverContent_H1_Text {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 60.009px */
  margin: 0;
}

.coverContent_P_Text {
  color: var(--white, #fff);
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 750px;
}

/* End of Cover Section */

/*begin responsive of cover section */

/* For small devices (mobile phones) */
@media (max-width: 475px) {
  body {
    font-size: 10px;
    /* other mobile-specific styles */
  }

  .SectionOneCover {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    background-image: url("../../assets/images/mobile.png");
    background-size: cover;
    height: 400px;
    width: 100%;
    align-items: flex-start;
  }

  .coverContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0px;
  }

  .coverContent_H4_Text {
    color: var(--white, #fff);
    font-size: 22px;
    font-family: "Gotham", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 30.005px */
    text-transform: uppercase;
    margin-top: 50px;
  }

  .coverContent_H1_Text {
    text-align: center;
    color: var(--white, #fff);
    font-family: "Gotham", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 60.009px */
  }
  .coverContent_P_Text {
    color: var(--white, #fff);
    text-align: center;
    font-family: "Gotham", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 324px;
  }
}

/* Section #one  */

.SectionTwo {
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
  padding: 100px 30px;
  gap: 10px;
}

.SectionTwoContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.SectionTwoContent h1 {
  /* width: 100%; */
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 40vw;
  margin-top: 0;
}
.SectionTwoContent p {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  padding: 5px;
  margin: 0;
  padding: 0;
  width: 40vw;
}
.SectionTwoBoxes {
  display: flex;
  padding: 0px 30px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 475px) {
  .SectionTwo {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .SectionTwoContent {
    padding: 0 20px;
    margin-left: 0;
    align-items: center;
    justify-content: center;
  }

  .SectionTwoContent h1 {
    width: 100%;
    color: #000;
    font-size: 24px;
    font-style: bold;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    padding: 0;
    margin-top: 5%;
    text-align: center;
  }
  .SectionTwoContent P {
    text-align: center;
    padding: 0;
    margin: 0;
    justify-content: center;
    width: auto;
  }
  .SectionTwoBoxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
