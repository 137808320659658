.AboutUsBig_Container {
  overflow: hidden;
}

/*   section one is a cover   */
.SectionOneCover {
  padding-bottom: 30px;
  background-size: cover;
  background-attachment: unset;
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.coverContent {
  justify-content: center;
  width: 516px;
  height: 78;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.coverContent h1 {
  background: linear-gradient(to right, #cc1d4a, #f89a5b);
  -webkit-background-clip: text;
  color: transparent;
  font-family: "Galifex", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.coverContent p {
  color: #fff;
  font-size: 22px;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 100%;
}

/* End of cover   */

/* Section Cover Responsive   */

@media (max-width: 475px) {
  .SectionOneCover {
    margin-bottom: 1px;
    background-size: cover;
    background-attachment: unset;
    height: 370px;
  }
  .coverContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2px;
  }

  .coverContent h1 {
    background: linear-gradient(to right, #cc1d4a, #f89a5b);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 30px;
    font-style: normal;
    font-family: "Gotham", sans-serif;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .coverContent p {
    text-align: center;
    color: #fff;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    font-family: "Gotham", sans-serif;
    line-height: 150.023%; /* 30.005px */
    width: 80%;
  }
}

/* begin  */

/*start who-we-are */
.SectionTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  background-image: url("../../assets/svg/who_are.svg");
  background-size: cover;
  background-attachment: unset;
  padding: 50px;
}
.SectionTwoContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-family: "Gotham", sans-serif;
  padding: 5px 10px;
}

.SectionTwoContent h1 {
  display: flex;
  justify-content: center;
  font-family: "Gotham", sans-serif;
  color: #1e0d12;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.title {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

/*who-we-are  pargraph   1  */
.first {
  padding: 20px;
  overflow-y: hidden;
  font-size: 25px;
  font-family: "Gotham", sans-serif;
  color: #1e0d12;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 35.005px;
}

/* End Section Two  */

/*SectionTwo Responsive */

@media (max-width: 475px) {
  .SectionTwo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-image: url("../../assets/svg/who_are.svg");
    background-size: cover;
    background-attachment: unset;
    padding: 50px;
  }
  .SectionTwoContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
  }

  /*who-we-are  pargraph   1  */
  .first {
    width: 350px;
    overflow-y: hidden;
    font-size: 24px;
    color: #1e0d12;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: 35.005px;
    padding: 10px;
    text-align: center;
    font-family: "Gotham", sans-serif;
  }
}
/* Section Three  */

.SectionThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 25px;
}

.SectionThree_Header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 350px;
}
.Title {
  color: #1e0d12;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  font-family: "Gotham", sans-serif;
}

.ourText {
  color: #1e0d12;
  width: 80%;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  font-family: "Gotham", sans-serif;
}

.SectionThree_PartOne {
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 50px;
  padding: 40px;
}

.SectionThree_PartTwo {
  display: flex;
  justify-content: start;
  flex-direction: row;
  gap: 50px;
  padding: 40px;
}

/* Begin Our Mission Responsive */

@media (max-width: 475px) {
  .SectionThree_PartOne {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
  }

  .SectionThree_PartTwo {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
  }

  .SectionThree_Header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    width: 350px;
    text-align: center;
  }

  .ourText {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e0d12;
    padding: 10px;
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 100%;
    font-family: "Gotham", sans-serif;
  }
}

.line {
  width: 80%;
  margin-left: 10%;
}
/* image section   */
/* Section Four   */

.SectionFour {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.whytitle {
  text-align: center;
  color: #1e0d12;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-bottom: 40px;
  padding-top: 40px;
  text-transform: uppercase;
  font-family: "Gotham", sans-serif;
}

.SectionFour_Part1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 5px;
}

.imagesOne {
  width: 650px;
  height: 317px;
  padding: 2px;
}

.imagesTwo {
  width: 650px;
  height: 317px;
  padding: 2px;
}

.imagesthree {
  width: 650px;
  height: 317px;
  padding: 2px;
}

.imagesfour {
  width: 650px;
  height: 317px;
  padding: 2px;
}

@media (max-width: 475px) {
  .SectionFour {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    text-align: center;
    padding: 20px;
  }

  .whytitle {
    display: flex;

    justify-content: center;

    color: #1e0d12;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    font-family: "Gotham", sans-serif;
  }

  .SectionFour_Part1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .imagesOne {
    background-repeat: no-repeat;

    padding: 5px;
  }
  .imagesTwo {
    background-size: 650px 317px;
    background-repeat: no-repeat;
    padding: 5px;
  }

  .imagesthree {
    background-size: 650px 317px;
    background-repeat: no-repeat;
    padding: 5px;
  }

  .imagesfour {
    background-size: 650px 317px;
    background-repeat: no-repeat;
    padding: 5px;
  }
}
/* content vary section */

/* section Five */

.contentvary {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  position: relative;
  height: 300px;
}
.About_ArrowBackIosIcon {
  position: absolute;
  left: -100px;
  bottom: 50%;
  cursor: pointer;
}
.About_ArrowForwardIosIcon {
  position: absolute;
  right: -100px;
  bottom: 50%;
  cursor: pointer;
}

.contentvary h4 {
  width: 873.062px;
  color: #4d4346;

  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140.023%; /* 33.605px */
  letter-spacing: -0.24px;
  font-family: "Gotham", sans-serif;
}

.contentvary p {
  color: #929292;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.contentvary {
  position: relative;
  /* other styles... */
}

.quoteContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust this height as needed */
}

.quoteContainer h4 {
  margin-bottom: 20px; /* Adjust as needed */
  text-align: center;
}

.quoteContainer p {
  margin-bottom: 10px; /* Adjust as needed */
  position: absolute;
  bottom: 60px;
}

.dots {
  position: absolute;
  bottom: 10px; /* Adjust distance from bottom */
}

.vary {
  display: flex;
  justify-content: center;
  padding: 80px;
}
/* section five responsive  */

@media (max-width: 475px) {
  .SectionFive {
    height: 400px;
  }
  .vary {
    display: flex;
    justify-content: center;
    padding: 10px;
    height: 400px;
  }
  .quoteContainer {
  }

  .contentvary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    height: 350px;
  }
  .contentvary h4 {
    width: 80%;
    color: #4d4346;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140.023%; /* 33.605px */
    letter-spacing: -0.24px;
    font-family: "Gotham", sans-serif;
  }
  .contentvary p {
    color: #929292;
    width: 80%;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
  }

  .vary {
    display: flex;
    justify-content: flex-start;
    padding: 0px;
  }
  .dots {
    position: absolute;
    bottom: 10px; /* Adjust distance from bottom */
  }
  .About_ArrowBackIosIcon {
    left: 10px;
  }
  .About_ArrowForwardIosIcon {
    right: 10px;
  }
}
/* Section six cover and styles its content   */

.SectionSix {
  background-size: cover;
  background-attachment: unset;
  height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SectionSix_CoverContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.SectionSix_CoverContent h1 {
  color: var(--white, #fff);
  font-size: 32px;
  font-family: "Galifex", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.SectionSix_CoverContent h4 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-family: "Gotham", sans-serif;
  font-weight: 400;
  line-height: 150.023%; /* 48.007px */
  text-align: center;
}

.smallspan {
  font-weight: 800;
  background: linear-gradient(
    97deg,
    #4b1120 -19.1%,
    #d21d4c 40.56%,
    #e24231 101.29%,
    #f89a5b 185.47%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 475px) {
  .ourpeoplecover {
    margin-bottom: 30px;
    background-size: cover;
    background-attachment: unset;
    height: 380px;
  }

  .OurPeopleCoverContent {
    margin-top: 25%;
    margin-left: 10%;
    justify-content: center;
    width: 80%;
    height: 95;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .OurPeopleCoverContent h1 {
    color: var(--white, #fff);

    width: 80%;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-right: 1%;
    text-align: center;
    font-family: "Gotham", sans-serif;
  }

  .OurPeopleCoverContent h4 {
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.023%; /* 48.007px */
    width: 80%;
    text-align: center;
    font-family: "Gotham", sans-serif;
  }
}

/*section Seven*/
/*the content of our people section*/

.SectionSeven {
  display: flex;
  justify-content: center;
  padding: 70px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 80px;
}

.SectionSeven_part1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.SectionSeven_Header {
  text-align: center;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 48.007px */
  text-transform: uppercase;

  font-family: "Gotham", sans-serif;

  background: linear-gradient(
    97deg,
    #4b1120 -19.1%,
    #d21d4c 40.56%,
    #e24231 101.29%,
    #f89a5b 185.47%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.SectionSeven_P1 {
  color: #1e0d12;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  font-family: "Gotham", sans-serif;
}

.SectionSeven_P2 {
  color: #1e0d12;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  font-family: "Gotham", sans-serif;
}

.Part2_Header_1 {
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 24.004px */
  text-transform: uppercase;
  font-family: "Gotham", sans-serif;

  background: linear-gradient(
    94deg,
    #4b1120 -72.55%,
    #d21d4c 34.42%,
    #e24231 143.3%,
    #f89a5b 294.19%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Part2_Header_2 {
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 24.004px */
  text-transform: uppercase;

  background: linear-gradient(
    94deg,
    #4b1120 -72.55%,
    #d21d4c 34.42%,
    #e24231 143.3%,
    #f89a5b 294.19%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.SectionSeven_P3 {
  color: #1e0d12;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  font-family: "Gotham", sans-serif;
}

/* Section Seven Responsive  */

@media (max-width: 475px) {
  .SectionSeven_Header {
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 48.007px */
    text-transform: uppercase;

    background: linear-gradient(
      97deg,
      #4b1120 -19.1%,
      #d21d4c 40.56%,
      #e24231 101.29%,
      #f89a5b 185.47%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .SectionSeven {
    display: flex;
    justify-content: center;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 50px;
  }
}
