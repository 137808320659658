.footerMobile_part1 {
  display: flex;
  flex-direction: column;
  padding: 16px 30px 24px 30px;
  align-items: center;
  justify-content: space-between;
  background: #d41e4d;
  gap: 32px;
}
.backgroundFooterMobile_svg {
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}
.footerMobile_part1_first {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 999;
  justify-content: space-between;
}
.part1Mobile_first_p {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 36px */
  text-transform: uppercase;
  flex: 1 0 0;
}
.part1Mobile_first_i {
  width: 50px;
  height: 50px;
}
.footerMobile_part1_second {
  display: flex;
  justify-content: center;
  align-items: center;
}
.part1Mobile_second_input {
  height: 30px;
  padding: 10px;
  border: none;
  width: 200px;
  flex: 1 0 0;
}
.part1Mobile_second_input::placeholder {
  font-size: 16px;
}
.part1Mobile_second_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
  height: 50px;
  background: #1e0d12;
  border: none;
  cursor: pointer;
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
}
/*  */
/*  */
.footerMobile_part_s {
  display: flex;
  padding: 48px 20px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  justify-content: center;

  background-color: #1e0d12;
  border-bottom: 1px solid #54333c;
}
.FooterMobile_part_s1 {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 51.2px */
  text-transform: uppercase;
  text-align: center;
  /* width: 200px; */
}
.FooterMobile_part_sButton {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fafafa;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%; /* 24.804px */
  text-transform: uppercase;
}
.FooterMobile_part_sIcon {
  color: #cc1d4a;
}
/*  */
/*  */

.footerMobile_part2 {
  display: flex;
  flex-direction: column;
  padding: 48px 30px;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #1e0d12;
  gap: 32px;
  overflow: hidden;
}
.FooterMobile_Part2_part1,
.FooterMobile_Part2_part2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  color: #fafafa;
  z-index: 999;
}
.FooterMobile_LinkContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 40px;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 20px 20px;
}

.FooterMobile_part3 {
  display: flex;
  /* width: 200px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;
  color: #fafafa;
}
.FooterMobile_AddContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 20px 20px;
}
.FooterMobile_Add_P {
  margin-top: 0;
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.FooterMobile_Add_M {
  margin: 0;
}
.FooterMobile_part4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex-shrink: 0;
  color: #fafafa;
}
.FooterMobile_ContactContainer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
  padding: 20px 20px;
}
.FooterMobile_Con_P {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.footerMobile_part1 a,
.footerMobile_part1 a:visited,
.footerMobile_part1 a:active,
.footerMobile_part1 a:hover,
.footerMobile_part2 a,
.footerMobile_part2 a:visited,
.footerMobile_part2 a:active,
.footerMobile_part2 a:hover,
.FooterMobile_part3 a,
.FooterMobile_part3 a:visited,
.FooterMobile_part3 a:active,
.FooterMobile_part3 a:hover,
.FooterMobile_part4 a,
.FooterMobile_part4 a:visited,
.FooterMobile_part4 a:active,
.FooterMobile_part4 a:hover {
  color: #fafafa;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.FooterMobile_part2_first_link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.FooterMobile_Main-link {
  font-size: 16px;
  font-weight: 600;
  color: #fafafa;
  font-family: "Gotham", sans-serif;
  font-style: normal;
  line-height: normal;
  z-index: 999;
}
.footerMobile_icon {
  width: 10px;
  height: 15px;
}
/*  */
/*  */
.footerMobile_part3 {
  border-top: 1px solid #54333c;
  display: flex;
  padding: 20px 80px;
  justify-content: space-between;
  align-items: center;
  background-color: #1e0d12;
}
.footerMobile_part3_first {
  color: #b54f49;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%;
}
.footerMobile_part3_second {
  display: flex;
  align-items: center;
  gap: 60px;
}
.FooterMobile_social {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fafafa;
  gap: 5px;
  font-family: "Gotham", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.FooterMobile_social_icon {
  width: 20px;
}
.footerMobile_part3_second,
.footerMobile_part3_second a,
.footerMobile_part3_second a:hover,
.footerMobile_part3_second a:visited,
.footerMobile_part3_second a:active {
  text-decoration: none;
}
.footerMobile {
  border-bottom: 4px solid #d62344;
  overflow: hidden;
}
.footer_part3_h4_Mobile {
  margin: 0;
  background: linear-gradient(93deg, #d41e4d 5.89%, #f89a5b 115.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
}
.FooterMobile_Part5 {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.FooterMobile_Part5_First {
  display: flex;
  align-items: center;
  gap: 42px;
  padding-left: 22px;
  margin-top: -10px;
}
.social_icon_youtube {
  width: 42px;
}
.KsaSNMob {
  margin-left: 19vw;
}
