.choice-button {
  padding: 10px 20px;
  margin: 5px;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.choice-button:not(.selected) {
  border: 1px solid #d4d4d4;
  background: #fff;
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.choice-button.selected {
  border: 1px solid #d4d4d4;
  background: #fff;
  color: #fafafa;
  font-family: "Gotham", sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  background-color: transparent;
  background-image: linear-gradient(100deg, #cc1d4a 42.48%, #f89a5b 111.16%);
}

.choice-svg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.choices-container {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-wrap: wrap;
}
@media (max-width: 475px) {
  .choice-button {
    width: 100%;
  }
  .choice-button:not(.selected) {
    font-size: 16px;
  }
  .choice-button.selected {
    font-size: 16px;
  }
  .choices-container {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
}
