.Rise_P2_accordion {
  width: 620px;
  min-width: 564px;
}

.Rise_P2_accordion_item {
  margin-bottom: 10px;
}

.Rise_P2_accordion_header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border-bottom: 2px solid #d21d4c;
}
.Rise_P2_accordion_title {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}

.Rise_P2_accordion_content {
  padding: 15px;
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
}
@media (max-width: 475px) {
  .Rise_P2_accordion {
    width: 350px;
    min-width: 350px;
  }
}
