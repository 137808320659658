.header_white {
  position: relative;
  display: flex;
  padding: 0 2.85vw 0 2.85vw;
  justify-content: space-between;
  align-items: center;
  border-top: 4px solid #d62344;
  background: #fafafa;
  z-index: 1000;
  color: #000;
}

.header_nav_white {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.85vw;
}
.header_nav_white_a {
  display: block;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: #1e0d12;
  border-bottom: 4px solid transparent;
}
.header_nav_white_a a,
.header_nav_white_a a:visited,
.header_nav_white_a a:active {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #1e0d12;
}
.header_nav_white_p {
  display: flex;
  font-family: "Inter", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1e0d12;
}
.header_nav_white_a:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid #cc1d4a;
  height: 80px;
  cursor: pointer;
}
.header_button_white {
  position: relative;
  width: 11.36vw;
  border: 2px solid #1e0d12;
  background-color: #fff;
  cursor: pointer;
}
.header_button_white:hover {
  background: linear-gradient(96deg, #cc1d4a 0%, #df352b 100%);
}
.header_button_white:hover .header_button_white_p {
  color: #fff;
}
.header_button_white a,
.header_button_white a:visited,
.header_button_white a:active {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #1e0d12;
}
.header_button_white_p {
  color: #1e0d12;
  font-family: "Inter", sans-serif;
  font-size: 1.13vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
