/* Start Style Page Careers */
.CareersContainer {
  display: flex;
  flex-direction: column;
  gap: 70px;
  overflow: hidden;
}

/* style careersPartOne */

.careersPartOneMain {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/images/Careers/CareersS1.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 430px;
}

.careersPartOne {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  position: absolute;
  left: 50px;
}
.careersPartOne_H2 {
  font-family: "Galifex";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(91deg, #cc1d4a -3.5%, #f89a5b 119.92%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.careersPartOne_H4 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.careersPartOne_P {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 672.73px;
  margin: 0;
}
/* style for mobile part one */
@media (max-width: 475px) {
  .careersPartOneMain {
    background-image: url(../../assets/images/Careers/CareersS1Mob.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .careersPartOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 24px;
    left: 0;
    margin-top: -200px;
  }
  .careersPartOne_H2 {
    font-size: 20px;
    text-align: center;
  }
  .careersPartOne_H4 {
    color: var(--white, #fff);
    font-family: "Gotham" sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
  }
  .careersPartOne_P {
    color: var(--white, #fff);
    font-family: "Gotham" sans-serif;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 350px;
    margin: 0;
  }
}
/* style careersPartTwo */
.careersPartTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}
.careersPartTwo_first h1 {
  display: flex;
  justify-content: center;
  align-self: center;
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.careersPartTwo_second {
}
.careersPartTwo_second_Container {
  display: flex;
  padding: 40px 80px 0px 80px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 64px 80px;
}
.careersP2_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 32px; */
}

.careersP2_S_E1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.careersP2_S_E1 h3 {
  color: #d41e4d;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}
.careersP2_S_E2 p {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
}
.CareersSpace {
  display: flex;
  justify-content: center;
  align-items: center;
  stroke-width: 1px;
  stroke: #d8d8d8;
}
/* style for mobile part two */
@media (max-width: 475px) {
  .careersPartTwo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }

  .careersPartTwo_first h1 {
    font-size: 20px;
  }
  .careersPartTwo_second {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .careersPartTwo_second_Container {
    padding: 0;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 32px;
  }
  .careersP2_S1 {
    padding: 0 32px;
  }

  .careersP2_S_E1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .careersP2_S_E1 h3 {
    font-size: 16px;
  }
  .careersP2_S_E2 p {
    font-size: 14px;
  }
  .CareersContainer {
    gap: 40px;
  }
  .CareersSpace {
    width: 300px;
    align-self: center;
  }
}
/*  */
/* style careers PartThree */
.careersPartThree {
  display: flex;
  padding: 0px 80px;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-bottom: 4vw;
}
.careersPartThree_First {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(91deg, #cc1d4a 1.29%, #df352b 38.94%, #f89a5b 77.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.careersPartThree_First h2 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.careersPartThree_FirstN p {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  margin: 0;
}
.CareersPic1 {
  flex-shrink: 0;
}
/*  */
/* style for mobile part three  */
@media (max-width: 475px) {
  .careersPartThree {
    padding: 0px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vw;
  }
  .CarS3 {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .careersPartThree_First {
    align-items: center;
  }
  .careersPartThree_First h2 {
    text-align: center;
    font-size: 20px;
  }
  .careersPartThree_First p {
    text-align: center;
    font-size: 14px;
  }
  .CareersPic1 {
    flex-shrink: 0;
    display: flex;
    margin-right: 30px;
    height: 220px;
    width: 350px;
    align-items: center;
    align-self: center;
  }
  .careersPartThree {
    display: flex;
    align-items: center;
  }
}

/* style careers part four  */

.careersPartFour {
  display: flex;
  padding: 0px 80px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.careersPartFourContain {
  display: flex;
  flex-direction: column;
}
.careersPartFour h1 {
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
.careersPartFour_First {
  display: flex;
  align-items: center;
  gap: 32px;
}
.careersPartFour_Element {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.careersPartFour_Element h3 {
  color: #4d4346;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  letter-spacing: -0.24px;
  margin: 0;
}
.careersPartFour_Element p {
  color: #929292;
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}
.careersPartFour_Second {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-top: -60px;
  margin-left: -35px;
}
/*  */
/* style for mobile Part four */
@media (max-width: 475px) {
  .careersPartFour {
    padding: 0px 30px;
    gap: 32px;
  }

  .careersPartFour h1 {
    font-size: 20px;
  }
  .careersPartFour_First {
    flex-direction: column;
  }
  .careersPartFour_Element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .careersPartFour_Element h3 {
    text-align: center;
    font-size: 12px;
    width: 300px;
  }
  .careersPartFour_Element p {
    font-size: 16px;
  }
  .careersPartFour_Second {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    margin-top: 0;
    margin-left: 0;
  }
  .CareersPartFourImg {
    width: 150px;
  }
}

/* style careers part Five */

.careersPartFive {
  display: flex;
  padding: 64px 40px 64px 40px;
  justify-content: center;
  align-items: flex-start;
  background: #f7f6f6;
  gap: 100px;
}
.careersPartFive_First {
  display: flex;
  /* width: 658px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-shrink: 0;
}
.careersPartFive_First p {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 600px;
}
.CarS3 {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.CarS3 button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  border: none;
  /* text style */
  color: #fff;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.careersPartFive_Head {
  background: linear-gradient(91deg, #cc1d4a 1.29%, #df352b 38.94%, #f89a5b 77.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.careersPartFive_Head h1 {
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 0%; /* 0px */
  text-transform: uppercase;
  width: 650px;
}
.careersPartFive_First a,
.careersPartFive_First a:visited,
.careersPartFive_First a:active {
  text-decoration: none;
}
.careersPartFive_First button {
  cursor: pointer;
}
.CareersPartFiveImg {
  width: 550px;
}
/* style for mobile Part five */

@media (max-width: 475px) {
  .careersPartFive {
    display: flex;
    flex-direction: column;
    padding: 30px 30px;
    justify-content: center;
    align-items: center;
    background: #f7f6f6;
    height: max-content;
  }
  .careersPartFive_First {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 1;
  }
  .careersPartFive_First p {
    font-size: 16px;
    text-align: center;
  }
  .careersPartFive_First button {
    margin-bottom: 24px;
  }

  .careersPartFive_Head h1 {
    font-size: 20px;
    text-align: center;
    line-height: 150%; /* 30px */
    width: 350px;
  }
  .careersPartFive_First p {
    width: 350px;
  }
  .CareersPartFiveImg {
    width: 100%;
    margin-bottom: 50px;
  }
}
