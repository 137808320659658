.Clients_Container {
  width: 100%;
  overflow: hidden; /* Add this line if not present */
}
.Clients_FirstPart {
  display: flex;
  padding: 124px 40px 80px 40px;
  justify-content: space-between;
  align-items: flex-start;

  background-color: #1e0d12;
}
.Clients_P1_S2_Hide {
  display: none;
}

.Clients_P1_S1 {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.Clients_P1_S1_H1 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Clients_P1_S1_P {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 602px;
}
.Clients_P1_S1_Button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  cursor: pointer;
  /* for text */
  color: var(--white, #fff);
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  border: none;
}
.Clients_P1_S2_Img {
  width: 600px;
}
@media (max-width: 475px) {
  .Clients_FirstPart {
    display: flex;
    padding: 30px;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .Clients_P1_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .Clients_P1_S1_P {
    font-size: 18px;
    text-align: center;
    width: 350px;
  }
  .Clients_P1_S1_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
  .Clients_P1_S2_Img {
    display: none;
  }
  .Clients_P1_S1 {
    align-items: center;
  }
  .Clients_P1_S2_Hide {
    display: flex;
    width: 300px;
  }
}
.Clients_SecondPart {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 50px;
}

.Clients_P2_S1 {
  display: flex;
  align-self: center;
}
.Clients_P2_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}
