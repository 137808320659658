.Odoo_Container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 56px;
}
@media (max-width: 475px) {
  .body {
    display: flex;
    flex-direction: column;
    gap: 56px;
  }
}

.Odoo_FirstPart {
  /* background-image: url(../../assets/svg/sap_banner.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}
.Odoo_P1_P1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -5vh;
}
.Odoo_P1_P1_H1 {
  color: #fff;
  font-family: "Galifex", sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 150.023%;
  margin: 0;
}
.Odoo_FirstPart_H1 {
  color: #fff;
  font-family: "Galifex", Arial, Helvetica, sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-top: 0;
}
.Odoo_FirstPart_P {
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 880px;
  margin: 0;
}
.OdooLogo {
  width: 150px;
}
.OdooStar {
  display: none;
}
/* Start Responsive for FirstPart */
@media (max-width: 475px) {
  /* About Body of page */
  .Odoo_Container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .Odoo_FirstPart {
    background-image: url(../../assets/images/sap_bannerMobile.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
  }
  .Odoo_P1_P1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0;
  }
  .Odoo_P1_P1_H1 {
    color: #fff;
    font-family: "Galifex", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 150.023%;
    /* padding-top: 15px; */
  }
  .Odoo_FirstPart_H1 {
    text-align: center;
    padding-top: 15px;
    color: #fff;
    font-family: "Galifex", Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%;
    margin-top: 0;
  }
  .Odoo_FirstPart_P {
    display: none;
  }
  .OdooLogo {
    width: 120px;
  }
  .OdooStar {
    display: none;
  }
}
/* End style sap First Part */
/* style second Part */
.Odoo_SecondPart {
  display: flex;
  padding: 56px 40px;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.Odoo_P2_S1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.Odoo_P2_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  width: 650px;
}
.Odoo_P2_S1_P {
  color: #515151;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 164%; /* 32.8px */
  width: 600px;
}
.Odoo_P2_S2 {
  width: 550px;
  border-radius: 10px;
}
/*beginSecondPartResponsive*/
@media (max-width: 475px) {
  .Odoo_SecondPart {
    display: flex;
    flex-direction: column;
    padding: 5px 5px;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }
  /*image*/
  .Odoo_P2_S2 {
    width: 350px;
  }
  .Odoo_P2_S1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding-left: 5px;
  }
  /*Header*/
  .Odoo_P2_S1_H1 {
    width: 250px;
    color: #1e0d12;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }
  .Odoo_P2_S1_P {
    display: flex;
    align-items: center;
    width: 350px;
    color: #515151;
    font-family: "Gotham" sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 164%; /* 32.8px */
    text-align: center;
    padding-left: 15px;
  }
}
/* end */
/* style Third Part */
.Odoo_ThirdPart {
  display: flex;
  padding: 56px 116px;
  flex-direction: column;
  align-items: center;
  gap: 72px;
  background: #fbfbfb;
}
.Odoo_P3_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}
.Odoo_P3_S2 {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  padding-bottom: 56px;
}
.Odoo_P3_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 370px;
  height: 380px;
  border-radius: 8px;
  gap: 24px;
  background: #eeeeee86;
}
.Odoo_P3_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}
.Odoo_P3_S2_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 33.005px */
  width: 327px;
  margin: 0;
}
.Odoo_P3_S2_Box1 {
  width: 100px;
  margin-top: -100px;
}
/* end */
@media (max-width: 475px) {
  .Odoo_P3_S2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 100px;
    padding-bottom: 56px;
  }
}
/* style Fourth Part */
.Odoo_FourthPart {
  display: flex;
  padding: 56px 30px;
  flex-direction: column;
  align-items: center;
  gap: 100px;
}
.Odoo_P4_First {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.Odoo_P4_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  width: 854px;
}
.Odoo_P4_S2_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 500px;
  margin: 0;
}

.Odoo_P4_S3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.Odoo_P4_S3_Box_Container {
  display: flex;
  gap: 32px;
  align-items: center;
}
.Odoo_P4_S3_Box {
  display: flex;
  width: 400px;
  height: 170px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  border-radius: 8px;
  background: #fbfbfb;
}
.Odoo_P4_S3_Box_H3 {
  color: var(--Red, #cc1d4a);
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 27.004px */
  padding-top: 10px;
}
.Odoo_P4_S3_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 300px;
  margin: 0;
}
/* end */
@media (max-width: 475px) {
  .Odoo_FourthPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .Odoo_P4_First {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .Odoo_P4_S1_H1 {
    color: #000;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    width: 275px;
    text-align: center;
  }
  .Odoo_P4_S2_P {
    color: #1e0d12;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    text-align: center;
    width: 300px;
  }

  .Odoo_P4_S3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .Odoo_P4_S3_Box_Container {
    display: flex;
    gap: 32px;
    flex-direction: column;
    align-items: center;
  }
}
/*End of Responsive Part Four*/
/* style Fifth Part */
.Odoo_FifthPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(
    0deg,
    rgba(255, 202, 167, 0) -10%,
    #ffd8be43 50%,
    rgba(255, 202, 167, 0) 105%
  );
}
.Odoo_P5_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  text-align: center;
}
.Odoo_P5_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
.Odoo_P5_S2_Container {
  display: flex;
  gap: 16px;
}
.Odoo_P5_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--white, #fff);
  width: 20vw;
  height: auto;
  padding: 10px 10px 30px 20px;
}
.Odoo_P5_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
  width: 20vw;
}
.Odoo_P5_S2_Box_P {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 18vw;
  margin: 0;
}
/* end */
@media (max-width: 475px) {
  .Odoo_P5_S1 {
    display: flex;
    justify-content: center;
  }
  .Odoo_P5_S1_H1 {
    width: 275px;
    color: #000;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
  }
  .Odoo_P5_S2_Container {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .Odoo_P5_S2_Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--white, #fff);
    width: 80vw;
    height: auto;
    padding: 10px 10px 30px 20px;
  }
  .Odoo_P5_S2_Box_H3 {
    color: #000;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 30.005px */
    width: 70vw;
  }
  .Odoo_P5_S2_Box_P {
    color: #000;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 70vw;
    margin: 0;
  }
}
/* style Sixth Part */
.Odoo_SixthPart {
  display: flex;
  padding: 56px 50px;
  align-items: center;
  justify-content: center;
  gap: 100px;
}
.Odoo_P6_S1Web {
  display: flex;
  align-items: center;
  width: 500px;
  border-radius: 10px;
}
.Odoo_P6_S2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}
.Odoo_P6_S2_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 38px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */
  text-transform: uppercase;
  width: 50vw;
  margin: 0;
}
.Odoo_P6_S2_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 50vw;
  margin: 0;
}
.Odoo_P6_S1Web {
  width: 35vw;
}
.Odoo_P6_S1Mobile {
  display: none;
}
/* end */
/*Partation six Responsive*/
@media (max-width: 475px) {
  .Odoo_SixthPart {
    flex-direction: column;
  }
  /*image*/
  .Odoo_P6_S1Web {
    /* display: none; */
    width: 350px;
    display: none;
  }
  .Odoo_P6_S1Mobile {
    display: block;
    width: 350px;
  }
  .Odoo_P6_S2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .Odoo_P6_S2_H1 {
    text-align: center;
    color: #000;
    font-family: "Gotham" sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 56px */
    text-transform: uppercase;
    width: 300px;
  }
  .Odoo_P6_S2_P {
    color: #1e0d12;
    font-family: "Gotham" sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
    width: 300px;
    text-align: center;
  }
}
/* style Seventh Part */
.Odoo_SeventhPart {
  display: flex;
  padding: 56px 30px;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  background: #fbfbfb;
}
.Odoo_P7_S1_H1 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
}
.Odoo_P7_S2_Box_Container {
  display: flex;
  gap: 24px;
}
.Odoo_P7_S2_Box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
  height: 183px;
  padding: 30px;
  background: linear-gradient(
    0deg,
    rgba(255, 202, 167, 0) 0%,
    #ffd8be7d 80%,
    rgba(255, 202, 167, 0) 100%
  );
}
.Odoo_P7_S2_Box_H3 {
  color: #000;
  text-align: center;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}
.Odoo_P7_S2_Box_P {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 340px;
  margin: 0;
}
/* Start part Seven Responsive */
@media (max-width: 475px) {
  .Odoo_SeventhPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #fbfbfb;
  }
  .Odoo_P7_S1_H1 {
    width: 275px;
    color: #000;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 44.8px */
    text-transform: uppercase;
    text-align: center;
  }
  .Odoo_P7_S2_Box_Container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .Odoo_P7_S2_Box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 350px;
    height: 183px;
    padding: 30px;
    background: linear-gradient(
      0deg,
      rgba(255, 202, 167, 0) 0%,
      #ffd8be7d 80%,
      rgba(255, 202, 167, 0) 100%
    );
  }
  .Odoo_P7_S2_Box_H3 {
    color: #000;
    text-align: center;
    font-family: "Gotham" sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 30.005px */
  }
  .Odoo_P7_S2_Box_P {
    color: #000;
    font-family: "Gotham" sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 24.004px */
    width: 250px;
    margin: 0;
  }
}
.Odoo_EigthPart {
  display: flex;
  padding: 56px 60px;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.Odoo_P8_S1 {
  display: flex;
  flex-direction: column;
}
.Odoo_P8_S1_Text {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.Odoo_P8_S1_H1 {
  color: #000;
  font-family: "Gotham" sans-serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 67.2px */
  text-transform: uppercase;
  width: 40vw;
  margin-bottom: 10px;
}
.Odoo_P8_S1_P {
  color: #1e0d12;
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
  /* width: 500px; */
}
.Odoo_P8_S1_H3 {
  color: #4d4346;
  font-family: "Gotham" sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
  letter-spacing: -0.24px;
  margin-top: 0;
  margin-bottom: 40px;
  /* width: 500px; */
}
.Odoo_P8_S1_Button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #d41e4d;
  border: none;
  cursor: pointer;
  /* for text */
  color: var(--white, #fff);
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
.Odoo_P8_S2 {
  width: 40vw;
  border-radius: 10px;
}
/*start Responsive for part Eight*/
@media (max-width: 475px) {
  .Odoo_EigthPart {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  .Odoo_P8_S2 {
    display: none;
  }
  .Odoo_P8_S1 {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .Odoo_P8_S1_H1 {
    color: #000;
    font-family: "Gotham" sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 67.2px */
    text-transform: uppercase;
    width: 300px;
    text-align: center;
  }
  .Odoo_P8_S1_P {
    color: #1e0d12;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.023%; /* 30.005px */
  }
  .Odoo_P8_S1_H3 {
    color: #4d4346;
    font-family: "Gotham" sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150.023%; /* 36.005px */
    letter-spacing: -0.24px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .Odoo_P8_S1_Button {
    padding: 16px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #d41e4d;
    border: none;
    cursor: pointer;
    /* for text */
    color: var(--white, #fff);
    font-family: "Inter" sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
