.Home_PartOne_slider {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

.slider-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: fill;
}

.Home_P1_S1_H1 {
  font-family: "Galifex", Arial, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 155.023%;
  text-transform: uppercase;
  background: linear-gradient(93deg, #d41e4d 5.89%, #f89a5b 115.02%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 100%;
}

.Home_P1_S1_Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  height: 100%;
}

.Home_P1_S1_Card_P {
  font-family: "Gotham", sans-serif;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.023%;
  width: 550px;
  height: 100%;
}

.Home_P1_S1_Card_First {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: 100%;
}

.Home_P1_S1_Card_Third {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  height: 100%;
}

.Home_P1_S1_Card_B {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid #f89a5b;
  background: transparent;
  color: #f89a5b;
  font-family: "Inter" sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  height: 100%;
}

.ArrowForwardIosIcon {
  color: #fff;
}
.ArrowForwardIosIconCont {
  z-index: 1000;
}
/*  */

@media (max-width: 475px) {
  .slider-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
  }
  .slider_background_Mobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    z-index: -1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: fill;
  }

  .Home_PartOne_slider {
    padding: 100px 20px;
    height: auto;
    /* margin-top: -500px; */
  }

  .Home_P1_S1_Container {
    gap: 30px;
    align-items: center;
  }

  .Home_P1_S1_Card_P {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }

  .Home_P1_S1_Card_First {
    gap: 30px;
    align-items: center;
  }

  .Home_P1_S1_Card_B {
    padding: 16px 24px;
    font-size: 14px;
  }
  .Home_P1_S1_H1 {
    font-size: 24px;
    text-align: center;
  }
  .Home_P1_S1_Card_Third {
    align-items: center;
  }
  .ArrowForwardIosIconCont {
    display: none;
  }
}
