/* style of submenu services */
/* style for all container */

.submenu_industriesMain {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  background: #17060b;
  box-shadow: 0px 8px 20px 0px rgba(30, 13, 18, 0.25);
  gap: 8px;
  position: absolute;
  top: 50;
  left: 0;
  z-index: 100000000000;

  align-items: flex-start;
  height: 60vh;
}
.submenu_contain_industries {
  display: flex;
  gap: 100px;
  /* align-self: stretch; */
  color: #fff;
  padding: 48px;
  align-items: flex-start;
  justify-content: space-between;
}
/* end style of all container */
/* style of info */
.submenu_info_industries {
  display: flex;
  flex: 1;
  padding: 64px 0px;
  padding-top: 0;
  flex-direction: column;
  align-items: flex-start;
}
.submenu_industries_name {
  color: #fff;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  margin-top: 0;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.submenu_discover_industries {
  align-self: stretch;
  color: #d21d4c;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 24.004px */
}
.submenu_help_industries {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 25vw;
  margin-bottom: 30px;
}
.submenu_industries_button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  background-color: transparent;
  align-items: center;
  gap: 8px;
  border: 1px solid #fff;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.submenu_industries_linkWithLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.industries_svg {
  width: 35px;
}
/* style of pages */

.submenu_industries_contain {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.submenu_industries_contain a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

.submenu_industries_contain a:hover {
  border-bottom: 1px solid #cc1d4a;
  background-color: #1e0d12;
}

.submenu_industries_link {
  display: flex;
  padding: 16px 9px 16px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #fff;
  color: #fff;

  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  width: 45vw;
}
a {
  text-decoration: none;
}
.hidden {
  display: none;
}
