.caseStudyEGICContainer {
  display: flex;
  flex-direction: column;
  gap: 56px;
  overflow: hidden;
}

/* style First Part */
.CaseStudyEGICFirstPart {
  background-image: url("../../assets/svg/banner_CaseStudy.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  height: 300px;
  padding: 56px 80px 32px 80px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  flex-shrink: 0;
  position: relative;
}
/* .CaseStudyEGICFirstPart::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.CaseStudyEGICFirstPart > * {
  position: relative;
  z-index: 2;
} */
.EGIC_P1_S1 {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.EGIC_P1_S1 a,
.EGIC_P1_S1 a:visited,
.EGIC_P1_S1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.EGIC_P1_S1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham" sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: uppercase;
}
.KeyboardArrowLeftIcon {
  color: #fff;
}
.EGIC_P1_S2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.EGIC_P1_H1 {
  color: var(--white, #fff);
  font-family: "Galifex", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 54.008px */
  margin: 0;
}
.EGIC_P1_H3 {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
  margin: 0;
  width: 874px;
  text-align: center;
}
.EGIC_P1_P {
  color: var(--white, #fff);
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  width: 874px;
  display: none;
}
.EGIC_P1_S2_Container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.casestudiesXP2OneMobileDamietta {
  display: none;
}

@media (max-width: 475px) {
  .CaseStudyEGICFirstPart {
    padding: 20px;
    height: 100%;
  }
  .EGIC_P1_S2_Container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }
  .EGIC_P1_H1 {
    text-align: center;
    font-size: 20px;
  }
  .EGIC_P1_S1 {
    width: 300px;
    justify-content: center;
    align-items: center;
  }
  .EGIC_P1_H3 {
    text-align: center;
    margin: 0;
    font-size: 20px;
    width: 324px;
  }
  .EGIC_P1_P {
    display: none;
  }
  .casestudiesXP2OneWebDamietta {
    display: none;
  }
  .casestudiesXP2OneMobileDamietta {
    display: block;
  }
}

/* style part two  */

.EGIC_P2_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EGIC_P2_S1_H1 {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  width: 874px;
}
.EGIC_P2_S1_H1 strong {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 30.005px */
}
@media (max-width: 475px) {
  .EGIC_P2_S1_H1 {
    width: 324px;
  }
  .EGIC_P2_S1_Container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/* end style First Part */
.CaseStudies_FifthPart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CaseStudies_P5_Content1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  width: 1200px;
  max-height: 600px;
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #ccc;
}
.CaseStudies_P5_Content1::-webkit-scrollbar {
  width: 12px;
}

.CaseStudies_P5_Content1::-webkit-scrollbar-track {
  background-color: transparent;
}

.CaseStudies_P5_Content1::-webkit-scrollbar-thumb {
  background: var(--Red, #cc1d498a);
}

.CaseStudies_P5_Content1::-webkit-scrollbar-thumb:hover {
  background: var(--Red, #cc1d4a);
}
.Case_P5_C1_Container {
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2), -5px -5px 15px rgba(0, 0, 0, 0.2);
}
.Case_P5_C1_Text_Damietta {
  padding: 20px;
  width: 100%;
}
.spaceLine {
  width: 300px;
  border-bottom: 1.5px solid black;
}
.CaseStudies_P5_C1_Img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CaseStudies_P5_C1_H3 {
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 150.023%; /* 30.005px */
}
.CaseStudies_P5_C1_P {
  font-family: "Gotham", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 30.005px */
}
.CaseMainLogo {
  width: 10vw;
}
.CaseAwsLogo {
  width: 10vw;
  margin-left: -30px;
}
.CaseSapLogo {
  width: 7vw;
}
.CaseStudies_P5_C1_Logo {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.Case_P5_C1_Text_Ul_Container li {
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 30.005px */
}
.CaseStudies_P5_C1_Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 400px;
  gap: 50px;
}
.CaseStudies_P5_C1_Right_P {
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%; /* 30.005px */
}
.CaseStudies_P5_C1_Right_P2 {
  display: flex;
  justify-content: space-between;
}
.Case_P5_C1_Right_P2_container {
  width: 150px;
}
.Case_P5_C1_Right_P2_H4 {
  color: rgb(29, 114, 204);
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-bottom: 0;
}
.Case_P5_C1_Right_P2_P {
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
  margin-top: 5px;
}
.CaseStudies_P5_C1_Right_P5 {
  display: flex;
  justify-content: flex-end;
}
.Case_P5_C1_Right_P5_container {
  width: 150px;
}
.Case_P5_C1_Right_P5_H4 {
  color: rgb(29, 114, 204);
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
  margin-bottom: 0;
}
.Case_P5_C1_Right_P5_P {
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
  margin-top: 5px;
}
.Case_P5_C1_Right_P5_P1 {
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
  margin-top: 5px;
  padding-left: 20px;
}
.CaseStudies_P5_C1_P_Strong {
  color: blue;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150.023%;
  padding: 0 5px;
  text-decoration: underline;
}
.CaseStudies_P5_C1_ImgWeb {
  height: 65vh;
}
.DamiettaHide {
  display: none;
}
@media (max-width: 475px) {
  .CaseStudies_FifthPart {
    padding: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .CaseStudies_FifthPart_H1 {
    font-size: 24px;
  }
  .CaseStudies_P5_Content1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    padding: 15px;
    align-items: center;
  }
  .Case_P5_C1_Container {
    flex-direction: column;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2), -2px -2px 5px rgba(0, 0, 0, 0.2);
    width: 90%;
    align-items: center;
    padding: 0 15px;
    justify-content: center;
  }
  .Case_P5_C1_Text_Damietta {
    padding: 0;
    width: 100%;
    /* width: 50px; */
  }
  .CaseStudies_P5_S1Web {
    display: none;
  }
  .CaseStudies_P5_C1_H3 {
    font-size: 20px;
    width: 300px;
    text-align: center;
  }
  .CaseStudies_P5_S1Mobile {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  .CaseStudies_P5_C1_P {
    width: 300px;
  }
  .CaseStudies_P5_C1_Right {
    width: 100%;
    align-items: center;
    gap: 20px;
  }
  .Case_P5_C1_Text_Ul_Main h4 {
    font-size: 20px;
    text-align: center;
  }
  .Case_P5_C1_Right_P2_container {
    width: 120px;
  }
  .CaseStudies_P5_C1_Right_P2 {
    width: 100%;
  }
  .caseStudiesP5Web {
    width: 100%;
  }
  .CaseStudies_P5_C1_Right_ImgWeb {
    width: 100%;
  }
  .CaseStudies_P5_C1_ImgWeb {
    width: 100%;
    display: none;
  }
  .CaseMainLogo {
    width: 20vw;
  }
  .CaseAwsLogo {
    width: 20vw;
    margin-left: -10px;
  }
  .CaseSapLogo {
    width: 15vw;
  }
}
.CaseStudiesMain_SecondPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 83px;
}
.CaseStudiesMain_P2_S1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
}
.CaseStudiesMain_P2_S1_H1 {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
}
.CaseStudiesMain_P2_S1_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  margin: 0;
  width: 1023px;
}
.CaseStudiesMain_P2_S2 {
  display: flex;
  padding: 0px 80px;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  align-self: stretch;
  /* padding-bottom: 80px; */
}
.CaseStudiesX_P2_Box {
  display: flex;
  width: 350px;
  height: 500px;
  padding: 10px 24px 24px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f7f6f6;
  gap: 24px;
}
.CaseStudiesX_P2_Box_S1_H3 {
  color: #000;
  font-family: "Galifex", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 36.005px */
}
.CaseStudiesX_P2_Box_S1_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  align-self: stretch;
}
.casestudiesXP2One {
  filter: grayscale(100%);
}

.casestudiesXP2One:hover {
  filter: grayscale(0%);
}
.CaseStudiesX_P2_Box_S3 {
  width: 100%;
}
.CaseStudiesX_P2_Box_S3_Button {
  display: flex;
  height: 62px;
  padding: 16px 9px 16px 0px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #1e0d12;
  cursor: pointer;
  width: 100%;
}
.CaseStudiesX_P2_Box_S3_A {
}
.CaseStudiesX_P2_Box_S3_Button_P {
  color: #1e0d12;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 30.005px */
  text-transform: capitalize;
}
@media (max-width: 475px) {
  .CaseStudiesMain_SecondPart {
    padding: 20px;
    align-items: center;
    justify-content: center;
  }
  .CaseStudiesMain_P2_S1 {
    align-items: center;
    text-align: center;
  }
  .CaseStudiesMain_P2_S1_H1 {
    font-size: 28px;
  }
  .CaseStudiesMain_P2_S1_P {
    width: 324px;
  }
  .CaseStudiesMain_P2_S2 {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .CaseStudiesX_P2_Box {
    width: 300px;
  }
}
