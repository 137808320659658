/* Container Styles */
.Clients_P2_S2_Container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* gap: 100px; */
  height: 350px;
  overflow: hidden;

  /* width: 100%; */
}
.MainOneCLients {
  width: 100%;
}
.Clients_P2_S2_Layer1_Wrapper_Container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;
}
/* Wrapper Styles */
.Clients_P2_S2_Layer1_Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 70%;
}

.ClientsP2L1Elaraby {
  height: 40px;
  width: 140px;
  filter: grayscale(100%);
}
.ClientsP2L1EGFOOD {
  height: 50px;
  width: 100px;
  filter: grayscale(100%);
}
.ClientsP2L1JUHAYNA {
  height: 50px;
  width: 100px;
  filter: grayscale(100%);
}
.ClientsP2L1Elqana {
  height: 60px;
  width: 150px;
  filter: grayscale(100%);
}
.ClientsP2L1OmanTel {
  height: 70px;
  width: 130px;
  filter: grayscale(100%);
}
.ClientsP2L1Sap {
  height: 40px;
  width: 80px;
  filter: grayscale(100%);
}
.ClientsP2L1Oscar {
  height: 50px;
  width: 140px;
  filter: grayscale(100%);
}
.Clients_P2_Amer {
  margin-top: -10px;
  height: 50px;
  width: 100px;
  filter: grayscale(100%);
}
.Clients_P2_Alfa {
  height: 60px;
  width: 150px;
  filter: grayscale(100%);
}
.Clients_P2_Edita {
  height: 60px;
  width: 130px;
  filter: grayscale(100%);
}
.Clients_P2_Ict {
  height: 100px;
  width: 150px;
  filter: grayscale(100%);
}
.Clients_P2_Ittihad {
  margin-top: -60px;
  height: 100px;
  width: 150px;
  filter: grayscale(100%);
}
.Clients_P2_metra {
  margin-top: -50px;
  height: 100px;
  width: 150px;
  filter: grayscale(100%);
}
.Clients_P2_Mido {
  margin-top: -40px;
  height: 100px;
  width: 150px;
  filter: grayscale(100%);
}
.Clients_P2_mobica {
  margin-top: -50px;
  height: 100px;
  width: 130px;
  filter: grayscale(100%);
}
.ClientsP2L2ImgSbm {
  height: 60px;
  width: 140px;
  filter: grayscale(100%);
}
.ClientsP2L2Affairs {
  height: 50px;
  width: 200px;
  filter: grayscale(100%);
}
.ClientsP2L2Communications {
  padding-top: 20px;
  height: 50px;
  width: 200px;
  filter: grayscale(100%);
}
.ClientsP2L2Planning {
  height: 100px;
  width: 250px;
  filter: grayscale(100%);
}
.ClientsP2ImgZain {
  height: 50px;
  width: 140px;
  filter: grayscale(100%);
}
.ClientsP2ImgStc {
  height: 50px;
  width: 80px;
  filter: grayscale(100%);
}
.Clients_P2_HarasQatar {
  margin-top: -30px;
  height: auto;
  width: 120px;
  filter: grayscale(100%);
}
.Clients_P2_Hay2a2 {
  margin-top: -50px;
  height: 150px;
  width: auto;
  filter: grayscale(100%);
}
.Clients_P2_naufar {
  height: 50px;
  width: 120px;
  filter: grayscale(100%);
}
.ClientsP2ImgPorto {
  margin-top: -50px;
  height: 150px;
  width: 250px;
  filter: grayscale(100%);
}
.ClientsP2L2Adlok {
  margin-top: -50px;
  height: 100%;
  width: 70px;
  filter: grayscale(100%);
}
.ClientsP2L2Haras {
  margin-top: -20px;
  height: 100px;
  width: 120px;
  filter: grayscale(100%);
}
.ClientsP2L2Etihad {
  padding-top: 20px;
  height: 120px;
  width: 120px;
  filter: grayscale(100%);
}
.Clients_P2_zenia {
  margin-top: -20px;
  height: 100px;
  width: 120px;
  filter: grayscale(100%);
}
.Clients_P2_Makan {
  margin-top: -30px;
  height: 120px;
  width: 120px;
  filter: grayscale(100%);
}
/* Layer Styles */
.Clients_P2_S2_Layer1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: auto;
  flex-grow: 1;
  flex-direction: column;
}
.Clients_P2_S2_Layer1 {
  gap: 70px;
}

.ClientsP2L1Elaraby:hover,
.ClientsP2L1EGFOOD:hover,
.ClientsP2L1JUHAYNA:hover,
.ClientsP2L1Elqana:hover,
.ClientsP2L1OmanTel:hover,
.ClientsP2L1Sap:hover,
.ClientsP2L1Oscar:hover,
.Clients_P2_Amer:hover,
.Clients_P2_Alfa:hover,
.Clients_P2_Edita:hover,
.Clients_P2_Ict:hover,
.Clients_P2_Ittihad:hover,
.Clients_P2_metra:hover,
.Clients_P2_Mido:hover,
.Clients_P2_mobica:hover,
.ClientsP2L2ImgSbm:hover,
.ClientsP2L2Affairs:hover,
.ClientsP2L2Communications:hover,
.ClientsP2L2Planning:hover,
.ClientsP2ImgZain:hover,
.ClientsP2ImgStc:hover,
.Clients_P2_HarasQatar:hover,
.Clients_P2_Hay2a2:hover,
.Clients_P2_naufar:hover,
.ClientsP2ImgPorto:hover,
.ClientsP2L2Adlok:hover,
.ClientsP2L2Haras:hover,
.ClientsP2L2Etihad:hover,
.Clients_P2_zenia:hover,
.Clients_P2_Makan:hover {
  filter: grayscale(0%);
}

@keyframes scrollBottomToTop {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(
      -50%
    ); /* Adjust this value based on your actual content size */
  }
}

.Clients_P2_S2_Layer1_Wrapper_Container {
  animation: scrollBottomToTop 50s linear infinite;
}

@media (max-width: 475px) {
  .Clients_SecondPart {
    padding: 0;
    padding-left: 50px;
  }
  .Clients_P2_S1_H1 {
    margin-left: -50px;
    text-align: center;
  }
  .Clients_P2_S2_Layer1_Wrapper {
    gap: 100px;
  }
}
