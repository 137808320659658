/* Main container using flex to position buttons and content */
.AwsSecondPartInfoContainer {
  display: flex;
  gap: 50px;
  flex-direction: column;
  align-items: center;
}

/* Buttons container */
.AwsSecondPartInfo_container {
  display: flex;
  gap: 40px;
  border-bottom: 3px solid #d8d8d8;
}

/* Button styles */
.AwsSecondPartInfo_button {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  cursor: pointer;
  color: #1e0d12;
  position: relative;
  font-family: "Gotham", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 27.004px */
}

/* Active button style */
.AwsSecondPartInfo_active {
  border-bottom: 3px solid #d41e4d;
  font-weight: 700;
}

/* Content container with padding and flex positioning */
.AwsSecondPartInfo_content {
  padding: 0 20px;
  display: flex;
  align-items: flex-start;
  gap: 50px;
  padding: 0 100px;
  margin-bottom: 50px;
}

/* Container for the content including h3 and paragraphs */
.Awscontent_MoreInfo_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Style for the h3 tag */
.Awscontent_MoreInfo_container h3 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 150.023%;
  margin-bottom: 20px;
  text-align: left;
  text-transform: none;
}

/* Style for paragraphs */
.Awscontent_MoreInfo_container_P {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 150.023%;
  margin: 0;
  margin-top: 10px;
  text-align: left;
  text-transform: none;
}

.Awscontent_MoreInfo_dotslist {
  list-style-type: disc;
  padding-left: 80px;
  margin-top: 20px;
}

.Awscontent_MoreInfo_list_item {
  color: #1e0d12;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 150.023%;
  margin-bottom: 10px;
  text-align: left;
  text-transform: none;
}

.AwsImg_MoreInfo {
  /* Styles for image container if needed */
}
@media (max-width: 475px) {
  .AwsSecondPartInfo_container {
    flex-direction: column;
  }
  .AwsSecondPartInfo_content {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0;
    flex-direction: column-reverse;
    margin: 0;
  }
  .Img_AWS_Second {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Awscontent_MoreInfo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .Awscontent_MoreInfo_container h3 {
    padding: 0 20px;
    color: #000;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 150.023%;
    margin-bottom: 20px;
    text-align: center;
  }

  /* Style for paragraphs */
  .Awscontent_MoreInfo_container_P {
    color: #1e0d12;
    font-family: "Gotham", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 150.023%;
    text-align: center;
    text-transform: none;
    width: 350px;
  }
  .Awscontent_MoreInfo_dotslist {
    list-style-type: disc;
    padding: 0 30px;
    margin-top: 20px;
    text-align: center;
  }
  .Awscontent_MoreInfo_list_item {
  }
}
