.slide {
  display: grid;
  grid-template-columns: repeat(1, 0.1fr); /* 2 columns with equal width */
  grid-template-rows: repeat(1, 0fr); /* 2 rows with equal height */
  gap: 10px; /* Optional gap between grid items */
  margin: 20px;
}

.boxinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fbfbfb;
  width: 300px;
  height: 160px;
  padding: 5px;
}

.boxinfo h1 {
  display: flex;
  font-family: "Gotham", sans-serif;
  text-align: center;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 10px;
}

.boxinfo p {
  display: flex;
  justify-content: flex-start;
  color: #000;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  padding: 5px;
}

/*controls have 2 arrow button */

/* control indicate to the active and nonactive svg + the right and left arrow */
.controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Gotham", sans-serif;
  padding-top: 20px;
}

/* indicate to two ARROW  */
.arrowslide {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  gap: 170px;
  justify-content: center;
  padding: 15px;
}

/* ACTIVATIO is the active and non-active Svg   */

/* the number of Svg increase relations to number of slide  "important ♥"  */
.activation {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
  padding: 25px;
}
