.accordion {
  width: 564px;
  min-width: 564px;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border-bottom: 2px solid #d21d4c;
}
.accordion-title {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%; /* 30.005px */
}

.accordion-content {
  padding: 15px;
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 24.004px */
  border-top: none;
}
@media (max-width: 475px) {
  .accordion {
    width: 100%;
    min-width: 325px;
    max-width: 325px;
  }
  .accordion-title {
    font-size: 16px;
  }
  .accordion-header {
    padding: 8px;
  }
}
