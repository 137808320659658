/* Container Styles */
.Main_Container_P8_New {
  width: 100vw;
  position: relative;
}
.Home_P8_S2_Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 56px;
  position: relative;
  overflow: hidden;
}
/* Wrapper Styles */
.Home_P8_S2_Layer1_Wrapper,
.Home_P8_S2_Layer2_Wrapper {
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
}

/* Layer Styles */
.Home_P8_S2_Layer1,
.Home_P8_S2_Layer2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  width: auto;
  flex-grow: 1;
  height: 150px;
}
.Home_P8_S2_Layer1 {
  gap: 80px;
}
.Home_P8_S2_Layer2 {
  gap: 120px;
}

.Slide_Home_P8_Img1 {
  height: 80px;
  margin-top: 10px;
}
.Slide_Home_P8_Img2 {
  height: 70px;
}
.Slide_Home_P8_Img3 {
  width: 200px;
}
.Slide_Home_P8_Img4 {
  width: 120px;
}
.Slide_Home_P8_Img5 {
  width: 350px;
  margin-top: 10px;
  margin-left: -40px;
}
.Slide_Home_P8_Img6 {
  height: 110px;
  margin-top: 10px;
  margin-left: -40px;
}
.Slide_Home_P8_Img7 {
  height: 115px;
  margin-top: 10px;
}
.Slide_Home_P8_Img8 {
  width: 130px;
  margin-top: 15px;
}
.Slide_Home_P8_Img9 {
  width: 110px;
  margin-top: 10px;
}
.Slide_Home_P8_Img10 {
  width: 110px;
}
.Slide_Home_P8_Img11 {
  width: 250px;
  margin-top: 25px;
}
.Slide_Home_P8_Img12 {
  width: 200px;
  margin-left: -40px;
}
.Slide_Home_P8_Img13 {
  height: 160px;
  margin-top: 10px;
  margin-left: -60px;
}
.Slide_Home_P8_Img14 {
  width: 180px;
}
.Slide_Home_P8_Img15 {
  width: 280px;
  margin-top: 10px;
}
.Slide_Home_P8_Img16 {
  width: 230px;
  margin-top: 20px;
  margin-left: -30px;
}
.Slide_Home_P8_Img17 {
  width: 220px;
  margin-top: 20px;
}
.Slide_Home_P8_Img18 {
  height: 70px;
  margin-top: 20px;
}
.Slide_Home_P8_Img19 {
  height: 70px;
  margin-top: 20px;
}
.Slide_Home_P8_Img20 {
  width: 140px;
  margin-top: 25px;
}
.Slide_Home_P8_Img21 {
  height: 40px;
  margin-top: 30px;
}
.Slide_Home_P8_Img22 {
  width: 200px;
  margin-top: 60px;
  margin-left: -20px;
}
.Slide_Home_P8_Img23 {
  width: 150px;
  margin-top: 20px;
  margin-left: -20px;
}
.Slide_Home_P8_Img24 {
  width: 130px;
  margin-top: 25px;
}
.Slide_Home_P8_Img25 {
  width: 210px;
  margin-right: 100px;
}
.Slide_Home_P8_Img26 {
  width: 170px;
}
.Slide_Home_P8_Img27 {
  width: 170px;
}
.Slide_Home_P8_Img28 {
  width: 160px;
  margin-left: -20px;
}
.Slide_Home_P8_Img29 {
  width: 180px;
}
.Slide_Home_P8_Img30 {
  width: 190px;
}
.Slide_Home_P8_Img31 {
  width: 180px;
}
.Slide_Home_P8_Img32 {
  width: 170px;
}
.Slide_Home_P8_Img33 {
  width: 100px;
}
.Slide_Home_P8_Img34 {
  width: 110px;
}
.Slide_Home_P8_Img35 {
  width: 140px;
}
.Slide_Home_P8_Img36 {
  width: 110px;
}
.Slide_Home_P8_Img37 {
  width: 200px;
}
.Slide_Home_P8_Img38 {
  width: 100px;
  margin-left: -15px;
}
.Slide_Home_P8_Img39 {
  width: 190px;
  margin-left: -15px;
}
.Slide_Home_P8_Img40 {
  width: 220px;
}
.Slide_Home_P8_Img41 {
  width: 180px;
}
.Slide_Home_P8_Img42 {
  width: 230px;
}
.Slide_Home_P8_Img43 {
  width: 170px;
  margin-left: -10px;
}
.Slide_Home_P8_Img44 {
  width: 200px;
  margin-left: -10px;
}
.Slide_Home_P8_Img45 {
  width: 130px;
}
.Slide_Home_P8_Img46 {
  width: 180px;
}
.Slide_Home_P8_Img47 {
  width: 180px;
}
.Slide_Home_P8_Img48 {
  width: 130px;
}
.Slide_Home_P8_Img49 {
  width: 170px;
  /* margin-right: 100px; */
}
.Slide_Home_P8_Img50 {
  width: 300px;
  margin-right: 100px;
}

.NoHover {
  filter: grayscale(100%);
}
.NoHover:hover {
  filter: grayscale(0%);
}

.Home_P8_S2_Layer2_Container {
  display: flex;
  /* gap: 40px; */
}

/* Animation Styles */
@keyframes scrollRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scrollLeftToRight {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.Home_P8_S2_Layer1_Wrapper {
  animation: scrollRightToLeft 50s linear infinite;
}

.Home_P8_S2_Layer2_Wrapper {
  animation: scrollLeftToRight 50s linear infinite;
}

.Home_P8_S2_Shadow_Right {
  position: absolute;
  height: 100%;
  right: 0;
  width: 120px;
  background: linear-gradient(-90deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
}
.Home_P8_S2_Shadow_left {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 120px;
  background: linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
  z-index: 10;
}
@media (max-width: 475px) {
  .Home_P8_S2_Shadow_Right {
    position: absolute;
    height: 100%;
    right: 0;
    width: 60px;
    background: linear-gradient(-90deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
    z-index: 10;
  }
  .Home_P8_S2_Shadow_left {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    background: linear-gradient(90deg, #fff 10%, rgba(255, 255, 255, 0) 100%);
    z-index: 10;
  }
}
@media (prefers-color-scheme: dark) {
  .left-wrapper-new,
  .right-wrapper-new {
    background-color: #fff;
  }
}
