.input_country {
  position: relative;
  display: inline-block;
  border-top: 1px solid #9d9d9d;
  padding-top: 0px;
}

.country_input {
  padding: 15px;
  width: 670px;
  border: 1px solid #9d9d9d;
  border-top: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
}

.input__labelCountry {
  position: absolute;
  top: -10px;
  left: 110px;
  background-color: #fff;
  padding: 0 4px;
  color: #9d9d9d;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
/* Add padding to the flag */
.iti__selected-flag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.iti__arrow {
  border-width: 5px 5px 0;
  margin-left: 10px;
}
.iti--separate-dial-code,
.iti__selected-flag {
  background: linear-gradient(180deg, #fff 0%, #f0f0f0 100%) !important;
}
@media (max-width: 475px) {
  .country_input {
    width: 280px;
  }
}
