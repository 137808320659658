/* style of submenu services */
/* style for all container */
.submenu_solutionsMain {
  display: flex;
  flex-direction: column;
  background: #17060b;
  box-shadow: 0px 8px 20px 0px rgba(30, 13, 18, 0.25);
  position: absolute;
  top: 50;
  left: 0;
  z-index: 100000000000;
  width: 100%;
  height: 60vh;
}
.submenu_contain_solutions {
  display: flex;
  color: #fff;
  padding: 48px;
  gap: 50px;
}
.summenu_solutions_secondPart {
  display: flex;
  gap: 24px;
}
/* end style of all container */
/* style of info */
.submenu_info_solutions {
  display: flex;
  flex-direction: column;
}
.submenu_solutions_first {
  color: #fff;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 24px;
  font-style: normal;
  margin-top: 0;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.submenu_solutions_second {
  align-self: stretch;
  color: #d21d4c;
  font-family: "Galifex", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150.023%;
}
.submenu_solutions_third {
  color: #fff;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

/* style of pages */

.submenu_solutions_contain {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submenu_solutions_contain a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
}

.submenu_solutions_contain a:hover {
  border-bottom: 1px solid #cc1d4a;
  background: #1e0d12;
}

.submenu_solutions_link {
  align-self: flex-start;
  display: flex;
  width: 500px;
  width: 40vw;
  padding: 16px 9px 16px 0px;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid #fff;
  color: #fff;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%;
}

/*  */
