.Manufacturing_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  background: var(--white, #fff);
  overflow: hidden;
}
.Manufacturing_FirstPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 50px;
  background: #fbfbfb;
  width: 95vw;
}
.Manufacturing_P1_S1 {
  display: flex;
  flex-direction: column;
}
.Manufacturing_P1_S1_H1 {
  color: var(--Red, #cc1d4a);
  font-family: "Gotham", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 56px */
  text-transform: uppercase;
  width: 40vw;
}
.Manufacturing_P1_S1_P {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150.023%; /* 34.505px */
  width: 50vw;
}
.Manufacturing_P2_Alfa2 {
  width: 250px;
}
.Clients_P2_EcoBat {
  width: 250px;
}
.Clients_P2_mobica2 {
  width: 250px;
}
.ManufacturingP2L1EGFOOD {
  width: 150px;
}
.Clients_P2_Makan2 {
  width: 150px;
}
.Clients_P2_zenia2 {
  width: 150px;
}
.Clients_P2_HSA {
  width: 150px;
  padding-left: 100px;
}
.ClientsP2L1Elaraby2 {
  width: 150px;
}
.Clients_P2_Ittihad2 {
  width: 150px;
}
.Clients_P2_EGIC {
  width: 150px;
}

/* end */
/* style Second Part */
.Manufacturing_SecondPart {
  display: flex;
  padding: 48px 103px 91.845px 104px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 72px;
  width: 100%;
}
.Manufacturing_P2_S1_H1 {
  color: #000;
  font-family: "Gotham", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
  text-transform: uppercase;
  text-align: center;
}
.Manufacturing_P2_S2 {
  display: flex;
  flex-direction: column;
  gap: 70px;
}
.Manufacturing_P2_S2_L {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 200px;
  width: 100%;
}
.ManufacturingP1S2Mobile {
  display: none;
}
.ManufacturingP1S2Web {
  width: 35vw;
}
@media (max-width: 475px) {
  .Manufacturing_Container {
    gap: 32px;
  }
  .Manufacturing_FirstPart {
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding-bottom: 0;
  }
  .Manufacturing_P1_S1 {
    align-items: center;
  }
  .Manufacturing_P1_S1_H1 {
    font-size: 24px;
    text-align: center;
    justify-content: center;
  }
  .Manufacturing_P1_S1_P {
    font-size: 16px;
    width: 350px;
    text-align: center;
    margin-bottom: 0;
  }
  .Manufacturing_P1_S2 {
    display: flex;
    align-items: center;
  }
  .ManufacturingP1S2Web {
    display: none;
  }
  .ManufacturingP1S2Mobile {
    display: block;
    width: 350px;
  }
  .Manufacturing_P2_S2_L {
    flex-direction: column;
  }
  .Manufacturing_P2_S1_H1 {
    font-size: 20px;
    text-align: center;
  }
  .Manufacturing_P2_S2 {
    gap: 50px;
  }
  .Manufacturing_P2_S2_L {
    gap: 50px;
  }
  .Clients_P2_HSA {
    padding-left: 0;
  }
  .Manufacturing_P2_Alfa2,
  .ManufacturingP2L1EGFOOD,
  .Clients_P2_EcoBat,
  .Clients_P2_Makan2,
  .Clients_P2_mobica2,
  .Clients_P2_zenia2,
  .Clients_P2_EGIC,
  .Clients_P2_HSA,
  .ClientsP2L1Elaraby2,
  .Clients_P2_Ittihad2 {
    width: 200px;
  }
}
